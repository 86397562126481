import React, { useState } from 'react';
import { useStyles } from "./AccountsProductsList.styles";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	List,
	ListItem,
	ListItemText,
	Typography,
	Button,
	DialogActions,
	Divider,
} from "@mui/material";

interface ProductsModalProps {
	products: string[],
	buyerName: string;
	modalType: 'reference' | 'selected';
	isModalOpen: boolean;
	setModalOpen: (open: boolean) => void;
}

export const ProductsModal: React.FC<ProductsModalProps> = ({
	products,
	buyerName,
	modalType,
	isModalOpen,
	setModalOpen,
}) => {
	const classes = useStyles();

	const [searchValue, setSearchValue] = useState("");

	const filteredProducts = products.filter((product) =>
		product.toLowerCase().includes(searchValue.toLowerCase())
	);

	return (
		<Dialog
			open={isModalOpen}
			onClose={() => setModalOpen(false)}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle className={classes.center}>
				{`${buyerName} - ${
					modalType === "reference" ? "Reference" : "Selected"
				} Products`}
			</DialogTitle>
			<DialogContent className={classes.productsDialogContent}>
				<TextField
					fullWidth
					placeholder="Search products..."
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
				/>
				<List className={classes.productsList}>
					{filteredProducts.length > 0 ? (
						filteredProducts.map((product, index) => (
							<React.Fragment key={index}>
								<ListItem>
									<ListItemText primary={product} />
								</ListItem>
								<Divider />
							</React.Fragment>
						))
					) : (
						<Typography className={classes.center}>No products</Typography>
					)}
				</List>
				<DialogActions className={classes.center}>
					<Button onClick={() => setModalOpen(false)} color="primary">
						Close
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};


