import { useCallback, useEffect, useState } from "react";
import { commonObjIncStoreZustand } from "../store/commonObjIncStore";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";

const useProductsItem = (itemId: string, userId: string, isObjective: boolean, searchPhrase?: string) => {
    const { query } = useQuery<IQueryFavoriteWithSearch>();
    const queryWholesalerId = query.wholesalerId;

    const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [productsPageSize, setProductsPageSize] = useState(10);

    const {products, productsCurrentPage, setProductsCurrentPage} = commonObjIncStoreZustand();

    useEffect(() => {
        if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

    const fetchProducts = useCallback(() => {
        commonObjIncStoreZustand.getState().fetchProducts(
			{
				wholesalerId,
				page: productsCurrentPage,
				limit: productsPageSize,
				searchPhrase,
			},
			itemId,
			userId,
			isObjective
		);
    }, [wholesalerId, productsCurrentPage, productsPageSize, itemId, userId, isObjective, searchPhrase])

    return {
        products,
        productsCurrentPage,
        setProductsCurrentPage,
        productsPageSize,
        setProductsPageSize,
        productsTotalPages: commonObjIncStoreZustand((state) => state.productsTotalPages),
		productsLoading: commonObjIncStoreZustand((state) => state.productsLoading),
		setProductsLoading: commonObjIncStoreZustand((state) => state.setProductsLoading),
		fetchProducts,
    }
}

export default useProductsItem;