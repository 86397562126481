import { useEffect, useMemo } from "react";
import { objectivesStoreZustand } from "../../objectivesIncentives/store/objectivesStore";
import useQuery, { IProductsQuery } from "../../../hooks/useQuery";

const useProducts = () => {
	const { query } = useQuery<IProductsQuery>();
	const { wholesalerId } = query;

	const products = objectivesStoreZustand((state) => state.products);

	useEffect(() => {
		objectivesStoreZustand.getState().fetchProducts({ wholesalerId });
	}, [wholesalerId]);

	const transformedData = useMemo(() => {
		if (!products)
			return {
				suppliers: [],
				brands: [],
				packageTypes: [],
				sizes: [],
				productTypes: [],
				trend: [],
				volumeRank: [],
				// units: [],
				brandFams: [],
			};

		const suppliers = products.productSuppliers
			.map((supplier) => supplier.title)
			.sort((a, b) => a.localeCompare(b));
		const brands = [
			...new Set(
				products.productSuppliers.flatMap((supplier) => supplier.brands)
			),
		].sort((a, b) => a.localeCompare(b));
		const packageTypes = products.productPackageTypes;
		const sizes = products.productSizes;
		const productTypes = products.productTags;
		// const units = products.units;
		const brandFams = products.brandFams;

		return {
			suppliers,
			brands,
			packageTypes,
			sizes,
			productTypes,
			// units,
			brandFams,
		};
	}, [products]);

	return {
		...transformedData,
	};
};

export default useProducts;
