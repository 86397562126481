import { useEffect, useState } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../../hooks/useQuery";
import { objectivesStoreZustand } from "../../store/objectivesStore";

const useReportGroupedObjectives = ({
	searchPhrase,
}: {
	searchPhrase?: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [secondaryPageSize, setSecondaryPageSize] = useState(10);

	const { objectives, reportingGroupedPage, setReportingGroupedPage } =
		objectivesStoreZustand();

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		const fetchAllChildren = async () => {
			const { objectives } = objectivesStoreZustand.getState();
			objectives.forEach((objective) => {
				if (objective.type === "grouped") {
					objectivesStoreZustand
						.getState()
						.fetchChildren({ wholesalerId }, objective._id);
				}
			});
			
		};
		objectivesStoreZustand.getState().fetchObjectivesReportTable(
			{
				wholesalerId,
				page: reportingGroupedPage,
				limit: secondaryPageSize,
				searchPhrase,
				type: "grouped",
			},
			fetchAllChildren
		);
	}, [wholesalerId, reportingGroupedPage, secondaryPageSize, searchPhrase]);

	return {
		objectives,
		reportingGroupedPage,
		setReportingGroupedPage,
		secondaryPageSize,
		setSecondaryPageSize,
		totalPages: objectivesStoreZustand((state) => state.totalPages),
		loading: objectivesStoreZustand((state) => state.loading),
		setLoading: objectivesStoreZustand((state) => state.setLoading),
	};
};

export default useReportGroupedObjectives;
