import React from "react";
import { getProducts } from "./productsSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import List from "@material-ui/core/List";
import ProductListItem from "./ProductListItem";
import { makeStyles } from "@material-ui/core/styles";
import useQuery, { IProductsQuery } from "../../hooks/useQuery";
import Box from "@material-ui/core/Box";
import Scroller from "../scroller/Scroller";
import Pagination from "@material-ui/lab/Pagination";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  contentScroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflowY: "auto",
    flex: 1,
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  pager: {
    padding: theme.spacing(1),
    margin: "auto",
  },
}));

export default function ProductsList() {
  const classes = useStyles();
  const productsIds = useSelector(
    (state: RootState) => state.products.productsList
  );
  const count = useSelector((state: RootState) => state.products.productsCount);
  const isLoading = useSelector(
    (state: RootState) => state.products.status === "loading"
  );
  const dispatch = useDispatch();
  const { query, setQuery } = useQuery<IProductsQuery>();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    dispatch(
      getProducts({
        wholesalerId,
        db,
        brand: query.brand,
        noBrand: query.noBrand,
        gpRank: query.gpRank,
        packageType: query.packageType,
        premise: query.premise,
        size: query.size,
        supplier: query.supplier,
        noSupplier: query.noSupplier,
        tags: query.tags,
        trend: query.trend,
        volumeRank: query.volumeRank,
        searchPhrase: query.searchPhrase,
        sponsored: query.sponsored,
        excluded: query.excluded,
        brandFams: query.brandFams,
        page: query.page ? Number(query.page) : undefined,
      })
    );
  }, [
    dispatch,
    JSON.stringify([
      query.brand,
      query.noBrand,
      query.gpRank,
      query.packageType,
      query.premise,
      query.size,
      query.supplier,
      query.noSupplier,
      query.tags,
      query.trend,
      query.volumeRank,
      query.brandFams
    ]),
    query.searchPhrase,
    query.page,
    query.sponsored,
    query.excluded,
    wholesalerId,
    db,
  ]);
  return (
    <Scroller>
      <Box className={classes.contentScroll}>
        <List className={classes.list} dense>
          {productsIds.map((productId) => (
            <ProductListItem
              key={productId}
              productId={productId}
              selected={query.selectedProducts?.includes(productId)}
            />
          ))}
        </List>
      </Box>
      {isLoading ? <LinearProgress /> : <Divider />}
      <Pagination
        page={Number(query.page) || 1}
        onChange={(_, value) => setQuery({ page: String(value) })}
        className={classes.pager}
        count={count}
      />
    </Scroller>
  );
}
