import React, { useState } from "react";
import { useStyles } from "./ArchiveComponent.styles";
import { Item } from "../types/commonObjIncTypes";
import ItemComponent from "../common/ItemComponent";
import { Incentive } from "../types/incentivesTypes";
import useArchivedIncentives from "../hooks/useArchivedIncentives";
import useHandleIncentiveUpdate from "../hooks/useHandleIncentiveUpdate";

export const ArchiveIncentiveComponent: React.FC = () => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState<string>("");

	const {
		archivedIncentives,
		archivedCurrentPage,
		loadingArchived,
		archivedTotalPages,
		setArchivedCurrentPage,
	} = useArchivedIncentives({
		searchPhrase: searchTerm !== "" ? searchTerm : undefined,
	});
	const { addIncentive, deleteIncentive, editIncentive } =
		useHandleIncentiveUpdate();

	const handleAdd = async (newData: Omit<Item, "_id">, parentId?: string) => {
		return await addIncentive(newData as Omit<Incentive, "_id">, parentId);
	};

	const handleEdit = (id: string, updated: Partial<Item>) => {
		editIncentive(id, updated as Partial<Incentive>);
	};

	const handleDelete = (id: string) => {
		deleteIncentive(id);
	};

	const handleFavorite = (id: string) => {
		const item = archivedIncentives.find((it) => it._id === id);
		if (item) {
			editIncentive(id, {
				...item,
				favorite: !item.favorite,
			});
		}
	};

	const handleDuplicate = (id: string) => {
		const item = archivedIncentives.find((it) => it._id === id);

		if (item) {
			const newName = `${item.name} copy`;
			const { _id: id, customId, users: originalUsers, ...restOfObject } = item;
			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
				totalAchieved: 0,
				percentageAchieved: 0,
				totalItems: 0,
				totalPayout: 0,
				checks: [],
				isEdited: false,
				archived: false,
			}));
			handleAdd(
				{
					...restOfObject,
					name: newName,
					favorite: false,
					users: initializedUsers,
					archived: false,
				},
				id
			);
		}
	};

	const handleArchived = (id: string) => {
		const item = archivedIncentives.find((it) => it._id === id);

		if (item) {
			editIncentive(id, {
				...item,
				archived: !item.archived,
			});
		}
	};

	const handleAction = (id: string) => {
		const item = archivedIncentives.find((it) => it._id === id);
		if (item) {
			console.log(item);
		}
	};
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	return (
		<div className={classes.container}>
			<div className={classes.listWrapper}>
				<ItemComponent
					currentPage={archivedCurrentPage}
					setCurrentPage={setArchivedCurrentPage}
					totalPages={archivedTotalPages}
					loading={loadingArchived}
					items={archivedIncentives}
					itemType="incentive"
					showAddButton={false}
					showDeleteButton={false}
					onAdd={handleAdd}
					onEdit={handleEdit}
					onDelete={handleDelete}
					onFavorite={handleFavorite}
					onDuplicate={handleDuplicate}
					onArchive={handleArchived}
					onAction={handleAction}
					onReset={() => {}}
					searchTerm={searchTerm}
					onSearchChange={handleSearchChange}
				/>
			</div>
		</div>
	);
};
