import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../app/store";
import { ISliceStatus, IUser, IWholesaler } from "../../interfaces";
import { IGeneralQuery } from "../../hooks/useQuery";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";

interface IUsersById {
	[customId: string]: IUser;
}
interface IState {
	previewWholesaler?: IWholesaler;
	prodWholesaler?: IWholesaler;
	previewUsersById: IUsersById;
	prodUsersById: IUsersById;
	allUsersIds: string[];
	status: ISliceStatus;
}

export type IListType = "sold" | "sell" | "remove";
export const listTypes: IListType[] = ["sold", "sell", "remove"];

const initialState: IState = {
	previewUsersById: {},
	prodUsersById: {},
	allUsersIds: [],
	status: "idle",
};

interface IDiffFetchedPayload {
	previewWholesaler: IWholesaler;
	prodWholesaler: IWholesaler;
	previewUsers: IUser[];
	prodUsers: IUser[];
}

export const diffSlice = createSlice({
	name: "diff",
	initialState,
	reducers: {
		statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
			state.status = action.payload;
		},
		diffFetched: (state, action: PayloadAction<IDiffFetchedPayload>) => {
			let allUsersIds: string[] = [];
			const previewUsersById: IUsersById = {};
			const prodUsersById: IUsersById = {};
			for (const user of action.payload.previewUsers) {
				allUsersIds.push(user.customId);
				previewUsersById[user.customId] = formatUser(user);
			}
			for (const user of action.payload.prodUsers) {
				allUsersIds.push(user.customId);
				prodUsersById[user.customId] = formatUser(user);
			}
			state.previewUsersById = previewUsersById;
			state.prodUsersById = prodUsersById;

			state.previewWholesaler = formatWholesaler(
				action.payload.previewWholesaler
			);
			state.prodWholesaler = formatWholesaler(action.payload.prodWholesaler);
			state.allUsersIds = uniq(allUsersIds);
			state.status = "ready";
		},
	},
	extraReducers: {
		"session/logOut": () => initialState,
	},
});

export const { statusChanged, diffFetched } = diffSlice.actions;

export const getDiff =
	(query: IGeneralQuery, options?: { silent?: boolean }): AppThunk =>
	async (dispatch) => {
		if (!options?.silent) {
			dispatch(statusChanged("loading"));
		}
		const [previewUsers, prodUsers, previewWholesaler, prodWholesaler] =
			await Promise.all([
				api.fetch({
					path: "/users",
					method: "GET",
					query: {
						wholesalerId: query.wholesalerId,
						db: "preview",
					},
				}),
				api.fetch({
					path: "/users",
					method: "GET",
					query: {
						wholesalerId: query.wholesalerId,
						db: "prod",
					},
				}),
				api.fetch({
					path: "/wholesaler",
					method: "GET",
					query: {
						wholesalerId: query.wholesalerId,
						db: "preview",
					},
				}),
				api.fetch({
					path: "/wholesaler",
					method: "GET",
					query: {
						wholesalerId: query.wholesalerId,
						db: "prod",
					},
				}),
			]);

		if (
			previewUsers.ok &&
			prodUsers.ok &&
			previewWholesaler.ok &&
			prodWholesaler.ok
		) {
			dispatch(
				diffFetched({
					previewUsers: previewUsers.payload,
					prodUsers: prodUsers.payload,
					previewWholesaler: previewWholesaler.payload,
					prodWholesaler: prodWholesaler.payload,
				})
			);
		}
	};

function formatUser(user: IUser): IUser {
	if (user.teamLead) {
		return sortObject({
			...user,
			routeNumber: user.routeNumber.map(Number).sort(),
			fpl: sortObject(user.fpl),
			teamLead: sortBy(user.teamLead, "customId"),
			gp: sortObject(user.gp),
			volume: sortObject(user.volume),
		});
	} else {
		return sortObject(user);
	}
}

function formatWholesaler(wholesaler: IWholesaler): IWholesaler {
	return sortObject({
		...wholesaler,
		allNonSellingDays: wholesaler.allNonSellingDays?.sort(),
		productSuppliers: sortBy(wholesaler.productSuppliers, "title").map(
			(item) => ({ title: item.title, brands: item.brands.sort() })
		),
		productSizes: wholesaler.productSizes.sort(),
		productPackageTypes: wholesaler.productPackageTypes.sort(),
		productPremises: wholesaler.productPremises.sort(),
		importedDays: wholesaler.importedDays.sort().map(String),
		productTags: wholesaler.productTags.sort(),
	});
}

function sortObject<T>(unorderedObj: T): T {
	return Object.keys(unorderedObj)
		.sort()
		.reduce((obj: any, key: any) => {
			// @ts-ignore
			obj[key] = unorderedObj[key];
			return obj;
		}, {}) as T;
}

export default diffSlice.reducer;
