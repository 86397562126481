import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
	container: {
		display: "flex",
		gap: "1rem",
		width: "100%",
	},
	select: {
		width: "22rem",
	},
});
