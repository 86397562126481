import { useEffect, useState } from "react";
import { objectivesStoreZustand } from "../../objectivesIncentives/store/objectivesStore";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";

const useObjectives = ({ searchPhrase }: { searchPhrase?: string }) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();

	const { wholesalerId } = query;
	const [pageSize, setPageSize] = useState(15);

	const loading = objectivesStoreZustand((state) => state.loading);

	const currentPage = objectivesStoreZustand((state) => state.currentPage);
	const setCurrentPage = objectivesStoreZustand(
		(state) => state.setCurrentPage
	);

	const setCurrentFavPage = objectivesStoreZustand(
		(state) => state.setCurrentFavPage
	);

	const totalFavPages = objectivesStoreZustand(
		(state) => state.totalFavPages
	);
	const currentFavPage = objectivesStoreZustand(
		(state) => state.currentFavPage
	);
	const totalPages = objectivesStoreZustand((state) => state.totalPages);

	const objectives = objectivesStoreZustand((state) => state.objectives);

	const favoriteObjectives = objectivesStoreZustand(
		(state) => state.favoriteObjectives
	);

	useEffect(() => {
		if (searchPhrase) {
			setCurrentPage(1);
		}
	}, [searchPhrase, setCurrentPage]);

	useEffect(() => {
		const fetchAllSubObjectives = () => {
			const { objectives } = objectivesStoreZustand.getState();
			objectives.forEach((objective) => {
				if (objective.type === "multi") {
					objectivesStoreZustand
						.getState()
						.fetchSubObjectives({ wholesalerId }, objective._id);
				}
			});
		};

		objectivesStoreZustand.getState().fetchObjectives(
			{
				wholesalerId,
				page: currentPage,
				limit: pageSize,
				searchPhrase,
			},
			fetchAllSubObjectives
		);
	}, [wholesalerId, currentPage, pageSize, searchPhrase]);

	useEffect(() => {
		objectivesStoreZustand.getState().fetchFavoriteObjectives({
			wholesalerId,
			favorite: true,
			page: currentFavPage,
			limit: pageSize,
			searchPhrase,
		});
	}, [wholesalerId, currentFavPage, pageSize, searchPhrase]);

	return {
		objectives,
		currentPage,
		loading,
		totalPages,
		setCurrentPage,
		setPageSize,
		favoriteObjectives,
		totalFavPages,
		currentFavPage,
		setCurrentFavPage,
	};
};

export default useObjectives;
