import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";
import { incentivesStoreZustand } from "../store/incentivesStore";
import { useEffect, useState } from "react";
import { Incentive } from "../../usersManagement/types/incentivesTypes";
import useUsers from "../../usersManagement/hooks/useUsers";
import useReportIncentives from "./useReportIncentives";
import useArchivedReportIncentives from "./useArchivedReportIncentives";
import { ItemsList } from "../Item/ItemsList";
import ItemsManagementReportTable, {
	IRow,
} from "../Item/ItemManagementReportTable";
import useUserUpdate from "../Item/useUserUpdate";
import { Dialog } from "@material-ui/core";
import { EditUserTargetPayout } from "../utils/EditUserTargetPayout";
import {
	getPayoutType,
	PayoutDetails,
} from "../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/utils";
import { initialPayoutDetails } from "../utils/utils";

const useStyles = makeStyles((_) => ({
	container: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		overflow: "hidden",
		maxHeight: "87vh",
	},
}));

export const IncentivesManagementSummary = () => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [filterType, setFilterType] = useState<string>("");

	const [activeSearch, setActiveSearch] = useState<{
		term: string;
		type: string;
	}>({
		term: "",
		type: "",
	});
	const [archivedSearch, setArchivedSearch] = useState<{
		term: string;
		type: string;
	}>({
		term: "",
		type: "",
	});

	const { rows, columns, setRows } = incentivesStoreZustand();

	const [innerLoading, setInnerLoading] = useState(false);
	const [showArchived, setShowArchived] = useState(false);

	const {
		incentives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
		totalPages,
		loading,
	} = useReportIncentives({
		searchPhrase: activeSearch.term !== "" ? activeSearch.term : undefined,
		type: activeSearch.type !== "" ? activeSearch.type : undefined,
	});

	const {
		archivedIncentives,
		archivedSecondaryCurrentPage,
		setArchivedSecondaryCurrentPage,
		archivedTotalPages,
		loadingArchived,
	} = useArchivedReportIncentives({
		searchPhrase: archivedSearch.term !== "" ? archivedSearch.term : undefined,
		type: archivedSearch.type !== "" ? archivedSearch.type : undefined,
	});

	const { users } = useUsers();
	const { updateUserDetails } = useUserUpdate({ filterType: filterType });

	const [selectedIncentives, setSelectedIncentives] = useState<Incentive[]>([]);

	const [shouldResetFilters, setShouldResetFilters] = useState(false);

	const [editRow, setEditRow] = useState<IRow>();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [payoutDetails, setPayoutDetails] =
		useState<PayoutDetails>(initialPayoutDetails);
	const [username, setUsername] = useState<string | undefined>("");
	const [minQuantityValue, setMinQuantityValue] = useState<number | undefined>(
		0
	);

	const resetFilters = () => {
		setShouldResetFilters(true);
	};

	const handleIncentiveselect = (selectedIncentives: Incentive[]) => {
		setInnerLoading(true);
		setSelectedIncentives(selectedIncentives);
		let currentRows: any[] = [];

		// Function to process a single incentive asynchronously
		const processIncentive = (incentive: Incentive, callback: any) => {
			const allIncentives = incentive.subIncentives
				? [...incentive.subIncentives]
				: [incentive];

			allIncentives.forEach((currentIncentive) => {
				if (currentIncentive.users) {
					currentIncentive.users.forEach((user) => {
						let parentName = null;

						if (currentIncentive.isSubIncentive) {
							const parentIncentive = incentives.find(
								(obj) => obj._id === currentIncentive.parentId
							);
							parentName = parentIncentive ? parentIncentive.name : null;
						} else if (currentIncentive.isGrouped) {
							const groupedParent = incentives.find((inc) =>
								inc.groupedIncentives?.some((groupedInc) => groupedInc._id === currentIncentive._id)
							);
							parentName = groupedParent ? groupedParent.name : null;
						}

						const currUser = users.find((u) => u.customId === user.user);
						currentRows.push({
							...user,
							userName: currUser?.name,
							route: currUser?.routeNumber.join(", "),
							userType: currUser?.role,
							incentive: currentIncentive.name,
							subIncentives:
								currentIncentive === incentive ? "-" : currentIncentive.name,
							startDate: currentIncentive.customPeriodStart,
							endDate: currentIncentive.customPeriodEnd,
							type: currentIncentive.type,
							quantity: currUser?.totalItems,
							status: user?.percentageAchieved || 0,
							target: user.isOpenEnded
								? "∞"
								: user.target === 0
								? currentIncentive.isOpenEnded
									? "∞"
									: currentIncentive.target
								: user.target,
							minQuantityValue: user.minQuantityValue,
							payout: user.isOpenEnded ? "∞" : user.totalPayout,
							image: user.images?.filter((image) => !image.rejected),
							rejectedImages: user.images?.filter((image) => image.rejected),
							payoutMTD: user?.totalAchieved || 0,
							supplierContribution: Math.floor(
								(incentive.supplierContribution / 100) * 
									(user?.totalAchieved ?? 0) || 0
							).toFixed(2),
							accounts: currentIncentive.accounts,
							products: currentIncentive.products,
							//Validation
							valueInput: currentIncentive.valueInput,
							valueInputType: currentIncentive.valueInputType,
							itemId: currentIncentive._id,
							userId: user.user,
							isObjective: false,
							eligibleQuantity: user.isAccountSpecific
								? user.eligibleQuantity
								: "-",
							isDelta: currentIncentive.measure?.label?.includes('Δ'),
							measure: currentIncentive.measure?.label,
							parentName: parentName
						});
					});
				}
			});

			// Use a callback to signal completion of processing for this incentive
			if (callback) callback();
		};

		// Function to process a chunk of incentives
		const processIncentivesChunk = (incentives: Incentive[], index = 0) => {
			if (index >= incentives.length) {
				setRows(currentRows); // Update the state with the new rows after processing
				setInnerLoading(false); // Hide the loading indicator
				return;
			}

			// Process the next incentive in the list
			processIncentive(incentives[index], () => {
				// Move on to the next incentive after a slight delay to allow UI updates
				setTimeout(() => processIncentivesChunk(incentives, index + 1), 0);
			});
		};

		// Start processing the selected incentives
		processIncentivesChunk(selectedIncentives);
	};

	const handleEditClick = (row: IRow) => {
		setEditRow(row);
		setUsername(row.userName);
		let selectedIncentive = incentives.find((inc) => inc._id === row.itemId);

		if (!selectedIncentive) {
			incentives.forEach((inc) => {
				if (inc.type === "multi") {
					const subObjective = inc.subIncentives?.find(
						(sub) => sub._id === row.itemId
					);
					if (subObjective) {
						selectedIncentive = subObjective;
					}
				} else if (inc.type === "grouped" && inc.groupedIncentives) {
					const groupedObjective = inc.groupedIncentives.find(
						(groupedInc) => groupedInc._id === row.itemId
					);
					if (groupedObjective) {
						selectedIncentive = groupedObjective;
					}
				}
			});
		}
		if (selectedIncentive && selectedIncentive.users) {
			const selectedUser = selectedIncentive.users.find(
				(user) => user.user === row.userId
			);
			if (selectedUser) {
				setPayoutDetails({
					target: selectedUser.target,
					payoutType: selectedUser.payoutType,
					threshold: selectedUser.threshold,
					cap: selectedUser.cap,
					isOpenEnded: selectedUser.isOpenEnded,
					allOrNothingAmount: selectedUser.payout,
					perActionAmount: selectedUser.payout,
					atRiskAmount: selectedUser.payout,
					advancedBudget: selectedUser.advancedBudget,
					advancedPayments: selectedUser.advancedPayments,
					isAccountSpecific: selectedUser.isAccountSpecific,
					isFullAmount: selectedUser.isFullAmount,
				});
				setMinQuantityValue(selectedUser.minQuantityValue);
			}
			setIsModalOpen(true);
		}
	};

	const handleSave = (
		updatedPayoutDetails: PayoutDetails,
		minQuantityValue: number
	) => {
		const row = rows.find(
			(row) => row.itemId === editRow?.itemId && row.userId === editRow?.userId
		);

		updateUserDetails(
			false,
			updatedPayoutDetails.target,
			getPayoutType(updatedPayoutDetails),
			updatedPayoutDetails.payoutType,
			row.itemId,
			row.userId,
			updatedPayoutDetails.isOpenEnded,
			updatedPayoutDetails.threshold,
			updatedPayoutDetails.cap,
			minQuantityValue,
			updatedPayoutDetails.isAccountSpecific,
			updatedPayoutDetails.isFullAmount,
			updatedPayoutDetails.advancedBudget,
			updatedPayoutDetails.advancedPayments
		);
		setIsModalOpen(false);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newSearchTerm = event.target.value;
		setSearchTerm(newSearchTerm);

		if (showArchived) {
			setArchivedSearch((prev) => ({ ...prev, term: newSearchTerm }));
		} else {
			setActiveSearch((prev) => ({ ...prev, term: newSearchTerm }));
		}
	};

	const handleFilterTypeChange = (type: string) => {
		const normalizedType =
			type === "imagevalidation" ? "imageValidation" : type;
		setFilterType(normalizedType);

		if (showArchived) {
			setArchivedSearch((prev) => ({ ...prev, type: normalizedType }));
		} else {
			setActiveSearch((prev) => ({ ...prev, type: normalizedType }));
		}
		setSelectedIncentives([]);
		resetFilters();
	};

	useEffect(() => {
		if (selectedIncentives.length === 0) {
			setRows([]);
		}
	}, [selectedIncentives, setRows]);

	useEffect(() => {
		if (showArchived) {
			setArchivedSearch({ term: searchTerm, type: filterType });
		} else {
			setActiveSearch({ term: searchTerm, type: filterType });
		}
	}, [showArchived, searchTerm, filterType]);

	return (
		<Paper className={classes.container}>
			<ItemsList<Incentive>
				itemType="Incentives"
				loading={showArchived ? loadingArchived : loading}
				items={showArchived ? archivedIncentives : incentives}
				currentPage={
					showArchived ? archivedSecondaryCurrentPage : secondaryCurrentPage
				}
				setCurrentPage={
					showArchived
						? setArchivedSecondaryCurrentPage
						: setSecondaryCurrentPage
				}
				totalPages={showArchived ? archivedTotalPages : totalPages}
				onItemSelect={handleIncentiveselect}
				selectedItems={selectedIncentives}
				onFilterChange={resetFilters}
				showArchived={showArchived}
				setShowArchived={setShowArchived}
				searchTerm={searchTerm}
				onSearchChange={handleSearchChange}
				filterType={filterType}
				onFilterTypeChange={handleFilterTypeChange}
			/>
			<ItemsManagementReportTable
				itemType="incentive"
				loading={innerLoading}
				columns={columns}
				rows={rows}
				selectedItem={selectedIncentives}
				shouldResetFilters={shouldResetFilters}
				setShouldResetFilters={setShouldResetFilters}
				onEdit={handleEditClick}
			/>
			<Dialog
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				fullWidth
				disableEnforceFocus
				PaperProps={{
					style: {
						position: "absolute",
						right: 0,
						minWidth: "50vw",
						maxHeight: "100vh",
						height: "100%",
						margin: 0,
						borderRadius: 0,
					},
				}}
			>
				<EditUserTargetPayout
					username={username}
					initialPayoutDetails={payoutDetails}
					onSave={handleSave}
					editRow={editRow}
					initialMinQuantityValue={minQuantityValue}
				/>
			</Dialog>
		</Paper>
	);
};
