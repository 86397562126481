import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MultiSelect, SingleSelect } from "../select/Select";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getWholesaler } from "../../warehouse/wholesalerWarehouse";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import { makeStyles } from "@material-ui/core/styles";
import useQuery, { IBuyersQuery } from "../../hooks/useQuery";
import { gpRanks, volumeRanks } from "../../interfaces";
import SpacingBox from "../spacingBox/SpacingBoxView";

interface IProps {
  onClose: () => void;
  isOpen: boolean;
}

const useStyles = makeStyles((_) => ({
  container: {},
}));

const sortingOptions = [
  "GP/CE * CE by account type",
  "GP/CE * CE company wide",
  "Highest GP/CE",
  "Highest CE",
];

export default function FormDialog(props: IProps) {
  const { query, setQuery } = useQuery<IBuyersQuery>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    dispatch(getWholesaler({ wholesalerId, db }));
  }, [dispatch, wholesalerId, db]);
  const filters = useSelector((state: RootState) => {
    let result = {
      brands: [],
      brandFams: [],
      packageTypes: [],
      premises: [],
      sizes: [],
      suppliers: [],
      tags: [],
    };
    if (!state.wholesalerWarehouse.details) {
      return result;
    }
    const {
      productSuppliers,
      productPackageTypes,
      productPremises,
      productSizes,
      productTags,
      brandFams
    } = state.wholesalerWarehouse.details;
    return {
      brands: uniq(flatten(productSuppliers.map((s) => s.brands))),
      brandFams: brandFams,
      packageTypes: productPackageTypes,
      premises: productPremises,
      sizes: productSizes,
      suppliers: productSuppliers.map((s) => s.title),
      tags: productTags,
    };
  });
  const onSelect = (key: string) => (
    val: string[] | string | number | undefined
  ) => setQuery({ [key]: val });
  const resetFilters = () => {
    setQuery({
      brand: undefined,
      brandFams: undefined,
      gpRank: undefined,
      packageType: undefined,
      size: undefined,
      supplier: undefined,
      tags: undefined,
      volumeRank: undefined,
      productsSortBy: undefined,
    });
  };

  return (
    <Dialog
      open={props.isOpen}
      className={classes.container}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Filter recommendations</DialogTitle>
      <DialogContent>
        <MultiSelect
          label="Brand"
          options={filters.brands}
          selected={query.brand}
          onSelect={onSelect("brand")}
        />
        <SpacingBox />
        <MultiSelect
          label="Brand Family"
          options={filters.brandFams}
          selected={query.brandFams}
          onSelect={onSelect("brandFams")}
        />
        <SpacingBox />
        <MultiSelect
          label="GP Rank"
          options={gpRanks}
          selected={query.gpRank}
          onSelect={onSelect("gpRank")}
        />
        <SpacingBox />
        <SingleSelect
          label="Package Type"
          options={filters.packageTypes}
          defaultValue={query.packageType}
          onSelect={onSelect("packageType")}
        />
        <SpacingBox />
        <MultiSelect
          label="Size"
          options={filters.sizes}
          selected={query.size}
          onSelect={onSelect("size")}
        />
        <SpacingBox />
        <MultiSelect
          label="Supplier Family"
          options={filters.suppliers}
          selected={query.supplier}
          onSelect={onSelect("supplier")}
        />
        <SpacingBox />
        <MultiSelect
          label="Tags"
          options={filters.tags}
          selected={query.tags}
          onSelect={onSelect("tags")}
        />
        <SpacingBox />
        <MultiSelect
          label="Volume Rank"
          options={volumeRanks}
          selected={query.volumeRank}
          onSelect={onSelect("volumeRank")}
        />
        <SpacingBox />
        <SingleSelect
          noDefault
          label="Sorting"
          options={sortingOptions}
          defaultValue={sortingOptions[Number(query.productsSortBy || 0)]}
          onSelect={(val) =>
            onSelect("productsSortBy")(String(sortingOptions.indexOf(val!)))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFilters} color="primary">
          Reset
        </Button>
        <Button onClick={props.onClose} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
