import React, { useState } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	IconButton,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "./AccountsProductsList.styles";
import useAccountsResultsItem from "../Item/useAccountsResultsItem";
import { ProductsModal } from "./ProductsModal";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


interface AccountsResultsListProps {
	itemId: string;
	userId: string;
	isDelta: boolean;
}

interface Account {
	_id: string;
	buyerId: string;
	buyerName: string;
	eligiblePods: number;
	referencePeriodPods: number;
	selectedPeriodPods: number;
}

export default function AccountsResultsList({
	itemId,
	userId,
	isDelta,
}: AccountsResultsListProps) {
	const [open, setOpen] = useState(false);
	const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
	const [selectedBuyerName, setSelectedBuyerName] = useState('');
	const [modalType, setModalType] = useState<'reference' | 'selected'>(
		'reference',
	);
	const [productsModalOpen, setProductsModalOpen] = useState<boolean>(false)

	const classes = useStyles();

	const {
		accountsResults: accounts,
		currentPage,
		setCurrentPage,
		totalPages,
		loading,
		fetchResults,
	} = useAccountsResultsItem(itemId, userId);

	const eligibleTotal = accounts.reduce((sum, acc) => sum + acc.eligiblePods, 0);
	const referenceTotal = accounts.reduce((sum, acc) => sum + acc.referencePeriodPods, 0);
	const selectedTotal = accounts.reduce((sum, acc) => sum + acc.selectedPeriodPods, 0);


	const handleClickOpen = () => {
		setOpen(true);
		fetchResults();
	};

	const handleClose = () => {
		setOpen(false);
		setCurrentPage(1);
	};

	const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
		setCurrentPage(value);
	};

	const [sortConfig, setSortConfig] = useState<{
		key: keyof Account;
		direction: "asc" | "desc";
	} | null>(null);

	const sortedAccounts = [...accounts].sort((a, b) => {
		if (!sortConfig) return 0;

		const { key, direction } = sortConfig;
		const aValue = a[key];
		const bValue = b[key];

		if (direction === "asc") {
			return aValue < bValue ? -1 : 1;
		} else {
			return aValue > bValue ? -1 : 1;
		}
	});

	const handleSort = (key: keyof Account) => {
		setSortConfig((prev) => {
			if (prev && prev.key === key) {
				return {
					key,
					direction: prev.direction === "asc" ? "desc" : "asc",
				};
			}
			return { key, direction: "asc" };
		});
	};

	const handleCellPress = (
		buyerId: string,
		type: 'reference' | 'selected',
	) => {
		const item = accounts.find(result => result.buyerId === buyerId);
		if (item) {
			setSelectedBuyerName(item.buyerName);
			setSelectedProducts(
				type === 'reference'
					? item.referencePeriodProductIds
					: item.selectedPeriodProductIds,
			);
			setModalType(type);
			setProductsModalOpen(true)
		}
	};
	return (
		<div>
			<Button
				variant="outlined"
				color="primary"
				onClick={handleClickOpen}
				className={classes.center}
			>
				See Results
			</Button>
			<Dialog disableEnforceFocus open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<DialogTitle className={classes.center}>Accounts Results</DialogTitle>
				<DialogContent className={classes.accountsDialogContent}>
					{loading ? (
						<Typography className={classes.centerText}>Loading...</Typography>
					) : (
						<>
							{accounts.length === 0 ? (
								<Typography className={classes.centerText}>
									No accounts found
								</Typography>
							) : (
								<div className={classes.accountsTable}>
									<Table stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell className={classes.accountsHeader}>
													<TableSortLabel
														active={sortConfig?.key === "buyerName"}
														direction={sortConfig?.key === "buyerName" ? sortConfig.direction : "asc"}
														onClick={() => handleSort("buyerName")}
													>
														Accounts ({accounts.length})
													</TableSortLabel>
												</TableCell>
												<TableCell className={classes.header}>
													<TableSortLabel
														active={sortConfig?.key === "eligiblePods"}
														direction={sortConfig?.key === "eligiblePods" ? sortConfig.direction : "asc"}
														onClick={() => handleSort("eligiblePods")}
													>
														Eligible ({eligibleTotal})
													</TableSortLabel>
												</TableCell>
												{isDelta && (
													<TableCell className={classes.header}>
														<TableSortLabel
															active={sortConfig?.key === "referencePeriodPods"}
															direction={sortConfig?.key === "referencePeriodPods" ? sortConfig.direction : "asc"}
															onClick={() => handleSort("referencePeriodPods")}
														>
															Ref ({referenceTotal})
														</TableSortLabel>
													</TableCell>
												)}
												<TableCell className={classes.header}>
													<TableSortLabel
														active={sortConfig?.key === "selectedPeriodPods"}
														direction={sortConfig?.key === "selectedPeriodPods" ? sortConfig.direction : "asc"}
														onClick={() => handleSort("selectedPeriodPods")}
													>
														New ({selectedTotal})
													</TableSortLabel>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{sortedAccounts.map((account: Account) => (
												<TableRow key={account._id}>
													<TableCell className={classes.accountsColumn}>
														{account.buyerName}
													</TableCell>
													<TableCell className={classes.column}>
														{account.eligiblePods}
													</TableCell>
													{isDelta && (
														<TableCell className={classes.column}>
															<span>{account.referencePeriodPods}</span>
															<IconButton className={classes.icon} onClick={() => handleCellPress(account.buyerId, 'reference')}>
																<KeyboardArrowRightIcon />
															</IconButton>
														</TableCell>
													)}
													<TableCell className={classes.column}>
														<span>{account.selectedPeriodPods}</span>
														<IconButton className={classes.icon} onClick={() => handleCellPress(account.buyerId, 'selected')}>
															<KeyboardArrowRightIcon />
														</IconButton>
													</TableCell>
												</TableRow>
											))}
										</TableBody>

									</Table>
								</div>
							)}
						</>
					)}
				</DialogContent>
				<Pagination
					className={classes.pagination}
					count={totalPages}
					page={currentPage}
					onChange={handleChangePage}
					color="primary"
				/>
				<DialogActions className={classes.center}>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
				<ProductsModal 
					isModalOpen={productsModalOpen}
					setModalOpen={setProductsModalOpen}
					products={selectedProducts}
					buyerName={selectedBuyerName}
					modalType={modalType}
				/>
			</Dialog>
		</div>
	);
}