import { useEffect } from 'react';
import { objectivesStoreZustand } from '../../objectivesIncentives/store/objectivesStore';
import useQuery, { IGeneralQuery } from '../../../hooks/useQuery';

const useAccountTypes = () => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	const accountTypes = objectivesStoreZustand((state) => state.accountTypes);
	const chains = objectivesStoreZustand((state) => state.chains);
	const locations = objectivesStoreZustand((state) => state.locations);
	useEffect(() => {
		objectivesStoreZustand.getState().fetchAccountTypes({ wholesalerId });
	}, [wholesalerId]);

	return {
		accountTypes,
		chains,
		locations
	};
};

export default useAccountTypes;
