import { useEffect, useState } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { incentivesStoreZustand } from "../../objectivesIncentives/store/incentivesStore";

const useIncentives = ({ searchPhrase }: { searchPhrase?: string }) => {
	const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;
	const [pageSize, setPageSize] = useState(15);

	const loading = incentivesStoreZustand((state) => state.loading);

	const currentPage = incentivesStoreZustand((state) => state.currentPage);

	const setCurrentPage = incentivesStoreZustand(
		(state) => state.setCurrentPage
	);

	const setCurrentFavPage = incentivesStoreZustand(
		(state) => state.setCurrentFavPage
	);
	const totalFavPages = incentivesStoreZustand(
		(state) => state.totalFavPages
	);
	const currentFavPage = incentivesStoreZustand(
		(state) => state.currentFavPage
	);

	const totalPages = incentivesStoreZustand((state) => state.totalPages);

	const incentives = incentivesStoreZustand((state) => state.incentives);

	const favoriteIncentives = incentivesStoreZustand(
		(state) => state.favoriteIncentives
	);

	useEffect(() => {
		if (searchPhrase) {
			setCurrentPage(1);
		}
	}, [searchPhrase, setCurrentPage]);

	useEffect(() => {
		const fetchAllSubIncentives = () => {
			const { incentives } = incentivesStoreZustand.getState();
			incentives.forEach((incentive) => {
				if (incentive.type === "multi") {
					incentivesStoreZustand
						.getState()
						.fetchSubIncentives({ wholesalerId }, incentive._id);
				}
			});
		};

		incentivesStoreZustand.getState().fetchIncentives(
			{
				wholesalerId,
				page: currentPage,
				limit: pageSize,
				searchPhrase,
			},
			fetchAllSubIncentives
		);
	}, [wholesalerId, currentPage, pageSize, searchPhrase]);

	useEffect(() => {
		incentivesStoreZustand.getState().fetchFavoriteIncentives({
			wholesalerId,
			favorite: true,
			page: currentFavPage,
			limit: pageSize,
		});
	}, [wholesalerId, currentFavPage, pageSize, searchPhrase]);

	return {
		loading,
		incentives,
		currentPage,
		favoriteIncentives,
		setCurrentPage,
		setPageSize,
		totalPages,
		totalFavPages,
		currentFavPage,
		setCurrentFavPage,
	};
};

export default useIncentives;
