import { useCallback, useEffect, useState } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../store/commonObjIncStore";

const useAccountsResultsItem = (itemId: string, userId: string) => {
	const { query } = useQuery<IGeneralQuery>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [pageSize, setPageSize] = useState(10);

	const {
		accountsResults,
		accountsResultsCurrentPage,
		setAccountsResultsCurrentPage,
		accountsResultsTotalPages,
		accountsResultsLoading,
		setAccountsResultsLoading,
		fetchAccountsResults,
	} = commonObjIncStoreZustand();

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	const fetchResults = useCallback(() => {
		fetchAccountsResults(itemId, userId, {
			wholesalerId,
			page: accountsResultsCurrentPage,
			limit: pageSize,
		});
	}, [
		fetchAccountsResults,
		wholesalerId,
		accountsResultsCurrentPage,
		pageSize,
		itemId,
		userId,
	]);

	return {
		accountsResults,
		currentPage: accountsResultsCurrentPage,
		setCurrentPage: setAccountsResultsCurrentPage,
		pageSize,
		setPageSize,
		totalPages: accountsResultsTotalPages,
		loading: accountsResultsLoading,
		setLoading: setAccountsResultsLoading,
		fetchResults,
	};
};

export default useAccountsResultsItem;
