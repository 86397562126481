import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../app/store";
import {
  IProduct,
  ISliceStatus,
  IGpRank,
  ITrend,
  IVolumeRank,
} from "../../interfaces";
import { IGeneralQuery } from "../../hooks/useQuery";
import snack from "../../utils/snack";

interface ISummary {
  gp: {
    thisYear: number;
    lastYear: number;
    delta: number;
  };
  volume: {
    thisYear: number;
    lastYear: number;
    delta: number;
  };
  gpCe: {
    thisYear: number;
    lastYear: number;
    delta: number;
  };
}
interface IState {
  productsById: {
    [customId: string]: IProduct;
  };
  productsList: string[];
  productsCount?: number;
  summary: ISummary;
  status: ISliceStatus;
}

const initialState: IState = {
  productsById: {},
  productsList: [],
  status: "idle",
  summary: {
    gp: {
      thisYear: 0,
      lastYear: 0,
      delta: 0,
    },
    volume: {
      thisYear: 0,
      lastYear: 0,
      delta: 0,
    },
    gpCe: {
      thisYear: 0,
      lastYear: 0,
      delta: 0,
    },
  },
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    productFetched: (state, action: PayloadAction<IProduct>) => {
      const product = action.payload;
      state.productsById[product.customId] = product;
    },
    productsFetched: (
      state,
      action: PayloadAction<{ results: IProduct[]; count: number }>
    ) => {
      const products = action.payload.results;
      if (!products.length) {
        state.status = "empty";
        state.productsCount = 0;
      } else {
        products.forEach((item) => {
          state.productsById[item.customId] = item;
        });
        state.status = "ready";
        state.productsCount = action.payload.count;
      }
      state.productsList = products.map((product) => product.customId);
    },
    productsPatched: (state, action: PayloadAction<IProduct[]>) => {
      const products = action.payload;

      products.forEach((item) => {
        state.productsById[item.customId] = item;
      });
    },
    summaryFetched: (state, action: PayloadAction<ISummary>) => {
      state.summary = action.payload || initialState.summary;
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const {
  statusChanged,
  productsFetched,
  summaryFetched,
  productFetched,
  productsPatched,
} = productsSlice.actions;

interface IGetProductsQuery extends IGeneralQuery {
  brand?: string[];
  noBrand?: string[];
  gpRank?: IGpRank[];
  packageType?: string;
  premise?: string;
  size?: string[];
  supplier?: string[];
  noSupplier?: string[];
  tags?: string[];
  trend?: ITrend;
  volumeRank?: IVolumeRank[];
  searchPhrase?: string;
  page?: number;
  sponsored?: string;
  excluded?: string;
  brandFams?: string[];
}

export const getProducts = (query: IGetProductsQuery): AppThunk => async (
  dispatch
) => {
  dispatch(statusChanged("loading"));
  const res = await api.fetch({
    path: "/products",
    method: "GET",
    query,
  });
  if (res.ok) {
    dispatch(productsFetched(res.payload));
  }
};

interface IPatchProductsQuery extends IGeneralQuery {
  customIds: string[];
}

interface IPatchProductsBody {
  patch: {
    sponsored?: {
      isSponsored?: boolean;
      isExcluded?: boolean;
    };
  };
}

export const patchProducts = (
  query: IPatchProductsQuery,
  body: IPatchProductsBody
): AppThunk => async (dispatch) => {
  snack.info("Updating...");
  const res = await api.fetch({
    path: "/products",
    method: "PATCH",
    query,
    body,
  });
  if (res.ok) {
    snack.success("Update complete 🚀");
    dispatch(productsPatched(res.payload));
  } else {
    snack.error("Update failed 😱");
  }
};

export const getProduct = (
  productId: string,
  query: IGeneralQuery
): AppThunk => async (dispatch) => {
  const res = await api.fetch({
    path: `/product/${encodeURIComponent(productId)}`,
    method: "GET",
    query,
  });
  if (res.ok) {
    dispatch(productFetched(res.payload));
  }
};

export const getProductsSummary = (
  query: IGetProductsQuery
): AppThunk => async (dispatch) => {
  dispatch(statusChanged("loading"));
  const res = await api.fetch({
    path: "/productsSummary",
    method: "GET",
    query,
  });
  if (res.ok) {
    dispatch(summaryFetched(res.payload));
  }
};

export default productsSlice.reducer;
