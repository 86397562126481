import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import BuyerSold from "./BuyerSold";
import BuyerSell from "./BuyerSell";
import BuyerRemove from "./BuyerRemove";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useSelector, useDispatch } from "react-redux";
import { getRecommendedProducts, listTypes } from "./buyersSlice";
import { RootState } from "../../app/store";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BuyerRecommendationSkeleton from "./BuyerRecommendationSkeleton";
import { usd, volume } from "../../utils/format";
import useQuery, { IBuyersQuery } from "../../hooks/useQuery";
import { IRecommendationSold, IRecommendationSell } from "../../interfaces";
import BuyersNoRecommendations from "./BuyersNoRecommendations";

interface IProps {
  buyerId: string;
  tab: number;
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
  },
  recommendationWrapper: {
    flex: 1,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(3),
    marginLeft: -theme.spacing(),
    marginRight: -theme.spacing(),
    marginBottom: -theme.spacing(3),
    overflowY: "auto",
    // hide scrollbar
    "&::-webkit-scrollbar": {
      width: 0,
      background: "transparent",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
  },
  accordion: {
    borderTopRightRadius: "0 !important",
    borderTopLeftRadius: "0 !important",
  },
  summary: {
    flexDirection: "column",
  },
  header: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    borderRadius: 0,
  },
  accordionDetails: {
    padding: 0,
  },
}));

export default function BuyerRecommendations(props: IProps) {
  const classes = useStyles();
  const { tab, buyerId } = props;
  const listKey = listTypes[tab];
  const products = useSelector(
    (state: RootState) => state.buyers.buyersById[buyerId]?.[listKey]
  );
  const accountType = useSelector(
    (state: RootState) => state.buyers.buyersById[buyerId]?.accountType
  );

  const { query } = useQuery<IBuyersQuery>();
  const dispatch = useDispatch();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    dispatch(
      getRecommendedProducts(buyerId, listKey, {
        wholesalerId,
        db,
        brand: query.brand,
        brandFams: query.brandFams,
        gpRank: query.gpRank,
        packageType: query.packageType,
        size: query.size,
        supplier: query.supplier,
        tags: query.tags,
        volumeRank: query.volumeRank,
        productsSortBy: query.productsSortBy
          ? Number(query.productsSortBy)
          : undefined,
      })
    );
  }, [
    dispatch,
    buyerId,
    listKey,
    wholesalerId,
    db,
    JSON.stringify([
      query.brand,
      query.brandFams,
      query.gpRank,
      query.packageType,
      query.size,
      query.supplier,
      query.tags,
      query.volumeRank,
      query.productsSortBy,
    ]),
  ]);

  return (
    <Box className={classes.recommendationWrapper}>
      {!products ? (
        <BuyerRecommendationSkeleton />
      ) : !products.length ? (
        <BuyersNoRecommendations />
      ) : (
        // @ts-ignore
        products?.map((product) => (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            key={product.customId}
            className={classes.accordion}
          >
            <AccordionSummary
              classes={{
                content: classes.summary,
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>{product.name}</Typography>
              <Typography variant="caption">
                Size: {product.size} / Supplier: {product.supplier}
              </Typography>
              <Typography variant="caption">
                GP: {usd(product.gp.total, 2)} / CE:{" "}
                {volume(product.volume.total, 2)} / GP/CE:{" "}
                {usd(product.gpCe.total, 2)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <TableContainer>
                {tab === 0 && (
                  <BuyerSold
                    {...(product as IRecommendationSold)}
                    accountType={accountType}
                  />
                )}
                {tab === 1 && (
                  <BuyerSell {...(product as IRecommendationSell)} />
                )}
                {tab === 2 && (
                  <BuyerRemove
                    {...(product as IRecommendationSold)}
                    accountType={accountType}
                  />
                )}
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Box>
  );
}
