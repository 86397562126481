export const objColumns = [
	"User Name",
	"Route",
	"Objective",
	"Type",
	"Sub Objective",
	"Start Date",
	"End Date",
	"User Type",
	"Status",
	"Quantity",
	"Measure",
	"Eligible Quantity",
	"Target",
	"Minimum Quantity",
	"Potential",
	"Payout MTD",
	"Image",
	"Rejected Images",
	"Accounts",
	"Products",
];

export const incColumns = [
	"User Name",
	"Route",
	"Incentive",
	"Type",
	"Sub Incentive",
	"Start Date",
	"End Date",
	"User Type",
	"Status",
	"Quantity",
	"Measure",
	"Eligible Quantity",
	"Target",
	"Minimum Quantity",
	"Potential",
	"Payout MTD",
	"Image",
	"Rejected Images",
	"Accounts",
	"Products",
];

export interface ITableCell {
	content?: string;
	value?: any;
	tooltip?: string;
}

export type ITableRows = ITableCell[][];

export type ISortDirection = "asc" | "desc";
