import { columnKeyMap } from "../utils/utils";

export const transformColumnName = (colName: string): string => {
	return columnKeyMap[colName] || colName;
};

const specificColumns: Record<string, string[]> = {
	objective: [
		"User Name",
		"Route",
		"Objective",
		"Type",
		"Measure",
		"Status",
		"Quantity",
		"Eligible Quantity",
		"Target",
		"Potential",
		"Payout MTD",
		"Accounts",
		"Products",
		"Accounts Results",
	],
	incentive: [
		"User Name",
		"Route",
		"Incentive",
		"Type",
		"Measure",
		"Status",
		"Quantity",
		"Eligible Quantity",
		"Target",
		"Potential",
		"Payout MTD",
		"Supplier Contribution",
		"Accounts",
		"Products",
		"Accounts Results",
	],
};
export const getColumnsToShowGrouped = (itemType: string) => {
	if (itemType === "objective") {
		return [
			"User Name",
			"User Type",
			"Route",
			"Grouped Objective",
			"Objective",
			"Start Date",
			"End Date",
			"Type",
			"Quantity",
			"Measure",
			"Eligible Quantity",
			"Grouped Quantity",
			"Individual Status",
			"Weight",
			"Image",
			"Rejected Images",
			"Grouped Status",
			"Target",
			"Potential",
			"Payout MTD",
			"Accounts",
			"Products",
			"Accounts Results",
		];
	} else {
		return [
			"User Name",
			"User Type",
			"Route",
			"Grouped Incentive",
			"Incentive",
			"Start Date",
			"End Date",
			"Type",
			"Quantity",
			"Measure",
			"Eligible Quantity",
			"Grouped Quantity",
			"Individual Status",
			"Weight",
			"Image",
			"Rejected Images",
			"Grouped Status",
			"Target",
			"Potential",
			"Payout MTD",
			"Accounts",
			"Products",
			"Accounts Results",
		];
	}
};

const typeSpecificColumns: Record<string, string[]> = {
	image: ["Image", "Rejected Images"],
	binary: [],
	quantity: [],
	validation: ["Input Value Type", "Input Value"],
	grouped: [],
	multi: [],
	imageValidation: ["Minimum Quantity", "Image", "Rejected Images"],
};

const getColumnSet = (type: string, specific: string[]) => {
	let combinedColumns = [
		...new Set([...specific, ...(typeSpecificColumns[type] || [])]),
	];

	if (type === "imageValidation") {
		const targetIndex = combinedColumns.indexOf("Target");
		if (targetIndex !== -1) {
			combinedColumns = [
				...combinedColumns.slice(0, targetIndex + 1),
				"Minimum Quantity",
				...combinedColumns
					.slice(targetIndex + 1)
					.filter((col) => col !== "Minimum Quantity"),
			];
		}
	}

	const accountsIndex = combinedColumns.indexOf("Accounts");
	if (accountsIndex !== -1) {
		combinedColumns.splice(accountsIndex, 1);
	}

	const productsIndex = combinedColumns.indexOf("Products");
	if (productsIndex !== -1) {
		combinedColumns.splice(productsIndex, 1);
	}

	const accountsResultsIndex = combinedColumns.indexOf("Accounts Results");
	if (accountsResultsIndex !== -1) {
		combinedColumns.splice(accountsResultsIndex, 1);
	}

	return [...combinedColumns, "Accounts", "Products", "Accounts Results"];
};

const determineColumns = (rows: any[], typeKey: string) => {
	const uniqueTypes = new Set(rows.map((row) => row.type));
	const type =
		uniqueTypes.size === 1 ? uniqueTypes.values().next().value : null;

	if (type) {
		return getColumnSet(type, specificColumns[typeKey]);
	} else {
		return getColumnSet("default", specificColumns[typeKey]);
	}
};

export const determineColumnsToShowObjective = (rows: any[]) => {
	return determineColumns(rows, "objective");
};

export const determineColumnsToShowIncentive = (rows: any[]) => {
	return determineColumns(rows, "incentive");
};
