import { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { objectivesStoreZustand } from "../store/objectivesStore";
import { mapRowToColumns } from "../utils/mapRowToColumns";
import { ButtonGroup, CircularProgress } from "@material-ui/core";
import { ExportXLSButton } from "../utils/ExportXLSXButton";
import { ExportCSVButton } from "../utils/ExportCSVButton";
import SearchField from "../utils/Search";
import ToggleColumnsButton from "../utils/ToggleColumnsButton";
import {
	determineColumnsToShowIncentive,
	determineColumnsToShowObjective,
	transformColumnName,
} from "./utils";
import TableContainer from "@material-ui/core/TableContainer";
import { Item } from "../../usersManagement/types/commonObjIncTypes";
import { incentivesStoreZustand } from "../store/incentivesStore";
import { useStyles } from "./ItemManagementReportTable.styles";
import React from "react";
import { ExportImagesCSV } from "../utils/ExportImagesCSV";
import { ExportImagesXLSX } from "../utils/ExportImagesXLSX";

export interface IRow {
	userName?: string;
	userType?: string;
	objective?: string;
	subObjectives?: string;
	incentive?: string;
	subIncentives?: string;
	startDate?: string;
	endDate?: string;
	type?: string;
	route?: string;
	itemId: string;
	userId: string;
	isOpenEnded: boolean;
	isAccountSpecific?: boolean;
}

interface IProps {
	itemType: string;
	loading: boolean;
	columns: string[];
	rows: IRow[];
	selectedItem?: Item[];
	shouldResetFilters: boolean;
	setShouldResetFilters: (value: boolean) => void;
	onEdit: (row: any) => void;
}

export default function ItemsManagementReportTable(props: IProps) {
	const {
		itemType,
		loading,
		columns,
		rows,
		shouldResetFilters,
		setShouldResetFilters,
		onEdit,
	} = props;

	const store =
		itemType === "objective"
			? objectivesStoreZustand()
			: incentivesStoreZustand();

	const {
		sortColumnName,
		sortDirection,
		setDirection,
		setColumnName,
		setOrder,
	} = store;

	const classes = useStyles();

	const [totals, setTotals] = useState({
		payout: 0,
		payoutMTD: 0,
		target: 0,
		quantity: 0,
		eligibleQuantity: 0,
		supplierContribution: 0,
	});
	const calculateTotals = (rows: any[]) => {
		let payout = 0;
		let payoutMTD = 0;
		let target = 0;
		let quantity = 0;
		let eligibleQuantity = 0;
		let supplierContribution = 0;

		rows.forEach((row) => {
			payout += parseFloat(row.payout || 0);
			payoutMTD += parseFloat(row.payoutMTD || 0);
			target += parseFloat(row.target || 0);
			quantity += parseInt(row.totalItems || 0);
			const rowEligibleQuantity = parseInt(row.eligibleQuantity);
			if (!isNaN(rowEligibleQuantity)) {
				eligibleQuantity += rowEligibleQuantity;
			}
			if (itemType === "incentive") {
				supplierContribution += parseFloat(row.supplierContribution || 0);
			}
		});

		setTotals({
			payout: parseFloat(payout.toFixed(2)),
			payoutMTD: parseFloat(payoutMTD.toFixed(2)),
			target: parseFloat(target.toFixed(2)),
			quantity: parseFloat(quantity.toFixed(2)),
			eligibleQuantity: parseFloat(eligibleQuantity.toFixed(2)),
			supplierContribution: parseFloat(supplierContribution.toFixed(2)), 
		});
	};

	useEffect(() => {
		calculateTotals(rows);
	}, [rows]);

	const [searchValue, setSearchValue] = useState<string>("");

	const handleSearchChange = (value: string) => {
		setSearchValue(value.toLowerCase());
	};

	const filteredRows = rows.filter((row) => {
		return (
			row.userName !== undefined &&
			(
				row.userName?.toLowerCase().includes(searchValue) ||
				row.userType?.toLowerCase().includes(searchValue) ||
				row.objective?.toLowerCase().includes(searchValue) ||
				(typeof row.subObjectives === "string" &&
					row.subObjectives.toLowerCase().includes(searchValue)) ||
				(row.startDate &&
					new Date(row.startDate)
						.toLocaleDateString()
						.toLowerCase()
						.includes(searchValue)) ||
				(row.endDate &&
					new Date(row.endDate)
						.toLocaleDateString()
						.toLowerCase()
						.includes(searchValue)) ||
				row.type?.toLowerCase().includes(searchValue) ||
				row.route?.toLowerCase().includes(searchValue)
			)
		);
	});

	const determineColumnsToShow =
		itemType === "objective"
			? determineColumnsToShowObjective(rows)
			: determineColumnsToShowIncentive(rows);

	const [showAllColumns, setShowAllColumns] = useState(false);

	const columnsToShow = showAllColumns ? columns : determineColumnsToShow;

	useEffect(() => {
		if (shouldResetFilters) {
			setSearchValue("");
			setShowAllColumns(false);
			setShouldResetFilters(false);
		}
	}, [shouldResetFilters, setShouldResetFilters]);

	const uniqueRowTypes = Array.from(new Set(filteredRows.map((row) => row.type)));

	return (
		<div className={classes.overflowHidden}>
			<ButtonGroup className={classes.buttonGroupContainer}>
				<SearchField
					label="Search in report table"
					value={searchValue}
					onChange={handleSearchChange}
				/>
				<ToggleColumnsButton
					showAllColumns={showAllColumns}
					setShowAllColumns={setShowAllColumns}
				/>
				<ExportXLSButton
					columns={columnsToShow}
					rows={filteredRows}
					totals={totals}
				/>
				<ExportCSVButton
					columns={columnsToShow}
					rows={filteredRows}
					totals={totals}
				/>
				{columnsToShow.some((column) => column === "Image") && [
					<ExportImagesCSV
						key="export-images-csv"
						rows={filteredRows}
						rowTypes={uniqueRowTypes}
					/>,
					<ExportImagesXLSX
						key="export-images-xlsx"
						rows={filteredRows}
						rowTypes={uniqueRowTypes}
					/>,
				]}
			</ButtonGroup>
			{!loading && (
				<TableContainer className={classes.tableContainerHeight}>
					<Table stickyHeader size="medium">
						<TableHead>
							<TableRow>
								{columnsToShow.map((column, index) => (
									<TableCell
										className={`${classes.headerCell} ${
											column === "Accounts Results"
												? classes.accountsResultsCell
												: ""
										}`}
										key={index}
									>
										<TableSortLabel
											active={sortColumnName === column}
											direction={sortDirection}
											onClick={() => {
												const transformedColName =
													transformColumnName(column);
												setDirection(
													sortDirection === "asc"
														? "desc"
														: "asc"
												);
												setColumnName(column);
												setOrder(
													sortDirection === "asc"
														? "desc"
														: "asc",
													transformedColName
												);
											}}
										>
											{column}
										</TableSortLabel>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredRows.length !== 0 &&
								filteredRows.map((row, index) => {
									const orderedRow = mapRowToColumns(
										row,
										columnsToShow,
										classes,
										onEdit
									);
									return (
										<TableRow
											key={index}
											className={classes.clickableRow}
										>
											{orderedRow.map(
												(cellContent, cellIndex) => (
													<React.Fragment
														key={cellIndex}
													>
														{cellContent}
													</React.Fragment>
												)
											)}
										</TableRow>
									);
								})}
							{filteredRows.length !== 0 && (
								<TableRow className={classes.totalRow}>
									<TableCell>Total</TableCell>
									{columnsToShow
										.slice(1)
										.map((column, index) => (
											<TableCell key={index}>
												{column === "Quantity"
													? isNaN(totals.quantity)
														? ""
														: totals?.quantity || 0
													: ""}
												{column === "Eligible Quantity"
													? isNaN(
															totals.eligibleQuantity
													  )
														? ""
														: totals?.eligibleQuantity ||
														  0
													: ""}
												{column === "Target"
													? isNaN(totals.target)
														? "∞"
														: totals.target
													: ""}
												{column === "Payout MTD"
													? isNaN(totals.payoutMTD)
														? "∞"
														: `$ ${totals.payoutMTD}`
													: ""}
												{column === "Potential"
													? isNaN(totals.payout)
														? "∞"
														: `$ ${totals.payout}`
													: ""}
												{column ===
													"Supplier Contribution" &&
												itemType === "incentive"
													? isNaN(
															totals.supplierContribution
													  )
														? "∞"
														: `$ ${totals.supplierContribution}`
													: ""}
											</TableCell>
										))}
								</TableRow>
							)}
						</TableBody>
					</Table>
					{filteredRows.length === 0 && (
						<div className={classes.emptyBox}>
							<Typography variant="subtitle1">No data</Typography>
						</div>
					)}
				</TableContainer>
			)}
			{loading && (
				<div className={classes.loadingFlex}>
					<CircularProgress size={"sm"} className={classes.loading} />
				</div>
			)}
		</div>
	);
}
