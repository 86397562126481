import { useState, useEffect } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { objectivesStoreZustand } from "../store/objectivesStore";
import { incentivesStoreZustand } from "../store/incentivesStore";

const useImages = ({ itemId, isObjective }: { itemId: string, isObjective: boolean }) => {
	const { query } = useQuery<IGeneralQuery>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	const rejectImage = (imageId: string, rejectedReason: string) => {
		if (isObjective) {
			objectivesStoreZustand.getState().rejectImage(query, itemId, imageId, rejectedReason);
		} else {
			incentivesStoreZustand.getState().rejectImage(query, itemId, imageId, rejectedReason);
		}
	};

	const unRejectImage = (imageId: string) => {
		if (isObjective) {
			objectivesStoreZustand
				.getState()
				.unRejectImage(query, itemId, imageId);
		} else {
			incentivesStoreZustand
				.getState()
				.unRejectImage(query, itemId, imageId);
		}
	};

	return {
		rejectImage,
		unRejectImage
	};
};

export default useImages;
