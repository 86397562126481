import { makeStyles } from "@material-ui/core";
import { indigo } from "@mui/material/colors";

export const useStyles = makeStyles({
	centerText: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	list: {
		flexGrow: 1,
		overflowY: "auto",
		height: "100%",
	},
	listItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		borderBottom: "1px solid #e0e0e0",
		paddingBottom: 10,
		marginBottom: 10,
	},
	pagination: {
		display: "flex",
		justifyContent: "center",
		marginTop: 20,
	},
	dialogContent: {
		display: "flex",
		flexDirection: "column",
		maxHeight: "60vh",
		overflow: "hidden",
		minHeight: "60vh",
	},
	center: {
		textAlign: "center",
	},
	accountsHeader: {
		width: "40%",
		backgroundColor: indigo[300],
		color: "white",
		whiteSpace: "nowrap",
	},
	header: {
		width: "20%",
		backgroundColor: indigo[300],
		color: "white",
		whiteSpace: "nowrap",
	},
	column: {
		width: "20%",
	},
	accountsColumn: {
		width: "40%",
	},
	accountsDialogContent: {
		display: "flex",
		flexDirection: "column",
		height: "70vh",
		overflowY: "auto",
	},
	accountsTable: {
		overflowX: "auto",
		height: "100%",
		overflowY: "auto",
	},
	productsDialogContent: {
		height: "50vh",
		display: "flex",
		flexDirection: "column",
		gap: "2rem",
	},
	productsList: {
		height: "75%",
		overflowX: "auto",
	},
	icon: {
		marginLeft: "1rem",
	},
	searchField: {
		marginLeft: "2rem",
		marginRight: "2rem",
	},
});
