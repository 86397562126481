import { useEffect } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useToRemoveTeamMembers = ({ userIds }: { userIds?: string[] }) => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	const teamMembersToRemove = commonObjIncStoreZustand(
		(state) => state.teamMembersToRemove
	);

	const removeMembers = async (userIds: string[]) => {
		const members = commonObjIncStoreZustand
			.getState()
			.fetchTeamMembersToRemove({ wholesalerId }, userIds);
		return members;
	};

	return {
		teamMembersToRemove,
		removeMembers,
	};
};

export default useToRemoveTeamMembers;
