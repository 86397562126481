import { useLocation, useHistory } from "react-router-dom";
import { IGpRank, ITrend, IVolumeRank } from "../interfaces";
import qs from "qs";
import Joi from "joi";
import { useMemo, useCallback } from "react";
import { ManualProductsFilters } from "../features/usersManagement/types/commonObjIncTypes";

export interface IGeneralQuery {
	wholesalerId?: string;
	db?: string;
}

export interface IGeneralQueryWithCopy extends IGeneralQuery {
	parentId?: string;
}

export interface IPaginatedQuery extends IGeneralQuery {
	premise?: string;
	objectiveId?: string;
	incentiveId?: string;
	searchPhrase?: string;
	accountType?: string;
	page: number;
	limit: number;
	isObjective?: boolean;
}
export interface IAccountsPaginated extends IGeneralQuery {
	premise?: string;
	objectiveId?: string;
	incentiveId?: string;
	searchPhrase?: string;
	accountType?: string;
	page: number;
	limit: number;
	isObjective?: boolean;
	chains?: string[];
	locations?: string[];
}

export interface IProductsPaginatedQuery extends IGeneralQuery {
	page: number;
	isObjective?: boolean;
	objectiveId?: string;
	incentiveId?: string;
	query: ManualProductsFilters;
}
export interface ISelectedCustomQuery extends IGeneralQuery {
	page: number;
	isObjective?: boolean;
	objectiveId?: string;
	incentiveId?: string;
}
export interface IPaginatedAccounts extends IGeneralQuery {
	itemId: string;
	userId: string;
	page: number;
	limit: number;
}
export interface IQueryFavorite extends IGeneralQuery {
	favorite?: boolean;
	page?: number;
	limit?: number;
}
export interface IQueryReportingAccounts extends IGeneralQuery {
	page?: number;
	limit?: number;
	isTeamLead?: boolean;
	searchPhrase?: string;
}
export interface IQueryFiltered extends IGeneralQuery {
	page?: number;
	limit?: number;
	parentId?: string;
}
export interface IQueryFavoriteWithSearch extends IGeneralQuery {
	favorite?: boolean;
	page?: number;
	limit?: number;
	searchPhrase?: string;
	type?: string;
}

export interface ISalesRepsQuery extends IGeneralQuery {
	bulkEdit?: string;
	sortBy?: string;
	team?: string;
	searchPhrase?: string;
	selectedUser?: string;
	compareWith?: string[];
}

export interface IFilesQuery extends IGeneralQuery {
	path?: string;
}

export interface IBuyersQuery extends IGeneralQuery {
	sortBy?: string;
	salesRep?: string;
	searchPhrase?: string;
	selectedBuyer?: string;
	page?: string;

	brand?: string[];
	brandFams?: string[];
	gpRank?: IGpRank[];
	packageType?: string;
	size?: string[];
	supplier?: string[];
	tags?: string[];
	volumeRank?: IVolumeRank[];
	productsSortBy?: string;
}

export interface IProductsQuery extends IGeneralQuery {
	searchPhrase?: string;
	brand?: string[];
	noBrand?: string[];
	gpRank?: IGpRank[];
	packageType?: string;
	premise?: string;
	size?: string[];
	supplier?: string[];
	noSupplier?: string[];
	tags?: string[];
	trend?: ITrend;
	volumeRank?: IVolumeRank[];
	selectedProducts?: string[];
	page?: string;
	sponsored?: string;
	excluded?: string;
	brandFams?: string[];
}

export interface IDiffQuery extends IGeneralQuery {
	group: string;
}

export default function useQuery<T>(schema?: Joi.Schema): {
	query: T;
	setQuery: (query: Partial<T>) => void;
	pushQuery: (query: Partial<T>) => void;
} {
	const { pathname, search } = useLocation();
	const history = useHistory();

	// Memoize the query object to prevent re-creation on every render
	const query = useMemo(() => {
		let parsedQuery = qs.parse(search, {
			ignoreQueryPrefix: true,
			allowPrototypes: true,
		});

		if (schema) {
			parsedQuery = Joi.attempt(parsedQuery, schema);
		}

		return parsedQuery;
	}, [search, schema]);

	// Use useCallback to memoize setQuery function
	const setQuery = useCallback(
		(newQueryPiece: Partial<T>) => {
			const newQuery = { ...query, ...newQueryPiece };
			Object.keys(newQueryPiece).forEach((key) => {
				if (!newQuery[key] || newQuery[key] === "undefined") {
					delete newQuery[key];
				}
			});
			history.replace({
				pathname,
				search: `?${qs.stringify(newQuery)}`,
			});
		},
		[history, pathname, query]
	);

	// Use useCallback to memoize pushQuery function
	const pushQuery = useCallback(
		(newQueryPiece: Partial<T>) => {
			const newQuery = { ...query, ...newQueryPiece };
			Object.keys(newQueryPiece).forEach((key) => {
				if (!newQuery[key] || newQuery[key] === "undefined") {
					delete newQuery[key];
				}
			});
			history.push({
				pathname,
				search: `?${qs.stringify(newQuery)}`,
			});
		},
		[history, pathname, query]
	);

	return {
		query,
		setQuery,
		pushQuery,
	};
}
