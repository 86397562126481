import {
	CircularProgress,
	FormControl,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Switch,
	FormControlLabel,
	TextField,
	Box,
} from "@material-ui/core";
import Select from "react-select";
import useProductsFilters from "../../../../hooks/useProductsFilters";
import { CustomObjIncProductFilters } from "../../../../types/commonObjIncTypes";
import { useStyles } from "./ProductsSelection.styles";
import { AddCircleOutline } from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import useSelectedCustomProducts from "../../../../hooks/useSelectedCustomProducts";
import { Pagination } from "@material-ui/lab";
import { useState } from "react";

type CustomProductsSelectionProps = {
	selectedProductsFilters: CustomObjIncProductFilters;
	setSelectedProductsFilters: React.Dispatch<
		React.SetStateAction<CustomObjIncProductFilters>
	>;
	item: any;
};

export const CustomProductsSelection: React.FC<
	CustomProductsSelectionProps
> = ({ selectedProductsFilters, setSelectedProductsFilters, item }) => {
	const classes = useStyles();
	const [currentSelectedPage, setCurrentSelectedPage] = useState(1);
	const [showTable, setShowTable] = useState(false);
	const [searchPhrase, setSearchPhrase] = useState("");
	const { suppliers, brands, packageTypes, sizes, productTypes, brandFams } =
		useProductsFilters();

	const {
		selectedCustomProducts,
		selectedCustomLoading,
		selectedCustomProductsTotalPages,
	} = useSelectedCustomProducts({
		page: currentSelectedPage,
		item,
		selectedProductsFilters,
		searchPhrase,
	});

	const getOptionsForFilter = (filterName: string) => {
		switch (filterName) {
			case "supplier":
				return suppliers.map((supplier) => ({
					value: supplier,
					label: supplier,
				}));
			case "brand":
				return brands.map((brand) => ({ value: brand, label: brand }));
			case "packageType":
				return packageTypes.map((packageType) => ({
					value: packageType,
					label: packageType,
				}));
			case "size":
				return sizes.map((size) => ({ value: size, label: size }));
			case "productType":
				return productTypes.map((productType) => ({
					value: productType,
					label: productType,
				}));
			case "brandFams":
				return brandFams?.map((bf) => ({ value: bf, label: bf }));
			default:
				return [];
		}
	};

	const handleProductSelectChange = (
		selectedOptions: any,
		selectName: string
	) => {
		setSelectedProductsFilters((prevSelectedProducts: any) => ({
			...prevSelectedProducts,
			[selectName]: selectedOptions
				? selectedOptions.map((option: any) => option.value)
				: [],
		}));
	};

	const handleSelectAll = (filterName: string) => {
		const allOptions = getOptionsForFilter(filterName).map(
			(option) => option.value
		);
		setSelectedProductsFilters((prevSelectedProducts: any) => ({
			...prevSelectedProducts,
			[filterName]: allOptions,
		}));
	};

	const handleRemoveAll = (filterName: string) => {
		setSelectedProductsFilters((prevSelectedProducts: any) => ({
			...prevSelectedProducts,
			[filterName]: [],
		}));
	};

	const filterLabels: { [key: string]: string } = {
		supplier: "Suppliers",
		brand: "Brands",
		packageType: "Package Types",
		size: "Sizes",
		productType: "Product Types",
		brandFams: "Brand Family",
	};

	const handlePageChange = (_event: any, page: number) => {
		setCurrentSelectedPage(page);
	};

	const handleShowTableChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setShowTable(event.target.checked);
	};

	const handleSearchPhraseChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSearchPhrase(event.target.value);
		setSelectedProductsFilters((prev) => ({
			...prev,
			searchPhrase: event.target.value,
		}));
	};

	return (
		<div className={classes.container}>
			<div>
				{Object.entries(selectedProductsFilters).map(
					([filterName, selectedValues]) => (
						<FormControl
							key={filterName}
							variant="outlined"
							className={classes.marginTop}
						>
							<div className={classes.rowContainer}>
								<Select
									className={classes.select}
									isMulti
									name={filterName}
									placeholder={`Select ${filterLabels[filterName]}`}
									options={getOptionsForFilter(
										filterName as keyof CustomObjIncProductFilters
									)}
									value={
										Array.isArray(selectedValues)
											? selectedValues.map((value: string) => ({
													value,
													label: value,
											  }))
											: []
									}
									onChange={(selectedOption: any) =>
										handleProductSelectChange(
											selectedOption,
											filterName as keyof CustomObjIncProductFilters
										)
									}
									styles={{
										menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
										valueContainer: (provided) => ({
											...provided,
											maxHeight: "100px",
											overflowY: "auto",
										}),
									}}
									menuPortalTarget={document.body}
								/>
								<div className={classes.buttonContainer}>
									<IconButton
										color="primary"
										size="small"
										onClick={() => handleSelectAll(filterName)}
									>
										<AddCircleOutline />
									</IconButton>
									<IconButton
										size="small"
										onClick={() => handleRemoveAll(filterName)}
									>
										<HighlightOffIcon />
									</IconButton>
								</div>
							</div>
						</FormControl>
					)
				)}
			</div>
			<FormControlLabel
				control={
					<Switch
						checked={showTable}
						onChange={handleShowTableChange}
						name="showTable"
						color="primary"
					/>
				}
				label="Show Selected Products"
				style={{ marginTop: "10px" }}
			/>
			{showTable && (
				<div>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						mb={2}
					>
						{/* <Typography variant="h6">Selected Products</Typography> */}
						<TextField
							variant="outlined"
							size="small"
							label="Search Products"
							value={searchPhrase}
							onChange={handleSearchPhraseChange}
							style={{
								width: "200px",
								marginBottom: "10px",
								marginTop: "10px",
							}}
						/>
					</Box>
					<div 
						className={classes.selectedTable}
						style={{
							height: selectedCustomProducts.length === 0 ? '15vh' : '45vh',
						}}
					>
						{selectedCustomLoading ? (
							<div className={classes.progress}>
								<CircularProgress size={"1rem"} />
							</div>
						) : selectedCustomProducts.length === 0 ? (
							<Typography className={classes.notFound} variant="body1">
								No products selected
							</Typography>
						) : (
							<Paper className={classes.table}>
								<TableContainer className={classes.tableContainer}>
									<Table stickyHeader aria-label="products table">
										<TableHead>
											<TableRow>
												<TableCell className={classes.headerCell}>
													Name
												</TableCell>
												<TableCell className={classes.headerCell}>
													Size
												</TableCell>
												<TableCell className={classes.headerCell}>
													Supplier
												</TableCell>
												<TableCell className={classes.headerCell}>
													Brand
												</TableCell>
												<TableCell className={classes.headerCell}>
													Premise
												</TableCell>
												<TableCell className={classes.headerCell}>
													Package
												</TableCell>
												<TableCell className={classes.headerCell}>
													Tag
												</TableCell>
												<TableCell className={classes.headerCell}>
													Brand Family
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{selectedCustomProducts.map((prod: any) => (
												<TableRow>
													<TableCell>{prod.name}</TableCell>
													<TableCell>{prod.size ? prod.size : "-"}</TableCell>
													<TableCell>
														{prod.supplier ? prod.supplier : "-"}
													</TableCell>
													<TableCell>{prod.brand ? prod.brand : "-"}</TableCell>
													<TableCell>
														{prod.premise ? prod.premise : "-"}
													</TableCell>
													<TableCell>
														{prod.packageType ? prod.packageType : "-"}
													</TableCell>
													<TableCell>{prod.tags ? prod.tags : "-"}</TableCell>
													{/* <TableCell>{product.units}</TableCell> */}
													<TableCell>
														{prod.brandFam && prod.brandFam.length > 0 ? prod.brandFam : "-"}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						)}
					</div>
					<div className={classes.pagination}>
						<Pagination
							count={selectedCustomProductsTotalPages}
							page={currentSelectedPage}
							onChange={handlePageChange}
							color="primary"
						/>
					</div>
				</div>
			)}
		</div>
	);
};
