import React, { useState, useEffect } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	List,
	ListItem,
	Avatar,
	Typography,
	IconButton,
	TextareaAutosize,
} from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { useStyles } from "./ImageListCell.styles";
import { ObjIncentiveImage } from "../../usersManagement/types/commonObjIncTypes";
import useImages from "./useImages";
import { formatDateAndTime } from "./utils";

interface ImageListCellProps {
	images: ObjIncentiveImage[];
	isRejected: boolean;
	isObjective: boolean;
	itemId: string;
}

export const ImageListCell: React.FC<ImageListCellProps> = ({
	images: propImages,
	itemId,
	isRejected,
	isObjective,
}) => {
	const [images, setImages] = useState<ObjIncentiveImage[]>(propImages);
	const [selectedImageId, setSelectedImageId] = useState<string | null>(null);
	const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
	const [open, setOpen] = useState(false);
	const [showRejectReason, setShowRejectReason] = useState(false);
	const [rejectReason, setRejectReason] = useState("");

	const { rejectImage, unRejectImage } = useImages({
		itemId,
		isObjective,
	});

	const classes = useStyles();

	useEffect(() => {
		setImages(propImages);
	}, [propImages]);

	const handleClickOpen = (index: number) => {
		setSelectedImageIndex(index);
		setSelectedImageId(images[index]._id);
		setOpen(true);
	};

	const handleClose = () => {
		setShowRejectReason(false);
		setOpen(false);
	};

	const handlePrevImage = () => {
		setShowRejectReason(false);
		if (selectedImageIndex !== null) {
			const newIndex = selectedImageIndex > 0 ? selectedImageIndex - 1 : images.length - 1;
			setSelectedImageIndex(newIndex);
			setSelectedImageId(images[newIndex]._id);
		}
	};

	const handleNextImage = () => {
		setShowRejectReason(false);
		if (selectedImageIndex !== null) {
			const newIndex = selectedImageIndex < images.length - 1 ? selectedImageIndex + 1 : 0;
			setSelectedImageIndex(newIndex);
			setSelectedImageId(images[newIndex]._id);
		}
	};

	const handleRejectClick = () => {
		setShowRejectReason(true);
	};

	const handleRejectSave = () => {
		if (selectedImageId) {
			rejectImage(selectedImageId, rejectReason);
		}
		setShowRejectReason(false);
		setRejectReason("");
		setOpen(false);
	};

	const handleUnreject = () => {
		if (selectedImageId) {
			unRejectImage(selectedImageId);
		}
		setOpen(false);
	};

	return (
		<div className={classes.container}>
			<List className={classes.list}>
				{images.map((image, index) => (
					<ListItem
						key={index}
						button
						onClick={() => handleClickOpen(index)}
						style={{
							flexDirection: "column",
						}}
					>
						<Avatar
							className={classes.image}
							variant="square"
							alt={image?.title || ""}
							src={image.imageUrl}
						/>
						<Typography variant="caption">{image?.buyerId || ""}</Typography>
					</ListItem>
				))}
			</List>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle className={classes.info}>
					{selectedImageIndex !== null && (
						<div>
							<Typography className={classes.title} variant="h6">
								{images[selectedImageIndex]?.title || ""}
							</Typography>
							<Typography variant="body2" color="textSecondary">
								<strong>Date:</strong>{" "}
								{(images[selectedImageIndex]?.date &&
									formatDateAndTime(images[selectedImageIndex]?.date)) ||
									"N/A"}
							</Typography>
							<Typography variant="body2" color="textSecondary">
								<strong>Account Name:</strong>{" "}
								{images[selectedImageIndex]?.account || "N/A"}
							</Typography>
							<Typography variant="body2" color="textSecondary">
								<strong>Buyer ID:</strong>{" "}
								{images[selectedImageIndex]?.buyerId || "N/A"}
							</Typography>
						</div>
					)}
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					{selectedImageIndex !== null && (
						<>
							<div className={classes.content}>
								<IconButton onClick={handlePrevImage}>
									<ArrowBack className={classes.navigationButton} />
								</IconButton>
								<img
									src={images?.[selectedImageIndex]?.imageUrl || ""}
									alt={images?.[selectedImageIndex]?.title || ""}
									className={classes.dialogImage}
								/>
								<IconButton onClick={handleNextImage}>
									<ArrowForward className={classes.navigationButton} />
								</IconButton>
							</div>
							{!showRejectReason && (
								<strong className={classes.notesInfo}>
									{isRejected ? "Rejection reason:" : "Sales Rep notes:"}
								</strong>
							)}{" "}
							<div className={classes.notes}>
								<Typography>
									{showRejectReason || isRejected
										? images[selectedImageIndex]?.rejectedReason || "-"
										: images[selectedImageIndex]?.notes || "-"}
								</Typography>
							</div>
						</>
					)}
					{showRejectReason && !isRejected && (
						<div className={classes.rejectContainer}>
							<Typography variant="body1">Reason (required):</Typography>
							<TextareaAutosize
								value={rejectReason}
								onChange={(e) => setRejectReason(e.target.value)}
								placeholder="Add rejection reason..."
								minRows={4}
								className={classes.textArea}
							/>
						</div>
					)}
				</DialogContent>
				<DialogActions className={classes.buttons}>
					<div className={classes.leftButtons}>
						{!showRejectReason && !isRejected && (
							<Button
								onClick={handleRejectClick}
								variant="contained"
								color="primary"
							>
								Reject
							</Button>
						)}
						{showRejectReason && (
							<>
								<Button
									onClick={() => setShowRejectReason(false)}
									color="primary"
								>
									Cancel
								</Button>
								<Button
									onClick={handleRejectSave}
									color="primary"
									disabled={!rejectReason.trim()}
								>
									Save
								</Button>
							</>
						)}
						{isRejected && (
							<Button
								onClick={handleUnreject}
								variant="contained"
								color="primary"
							>
								Restore Image
							</Button>
						)}
					</div>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
