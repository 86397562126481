export const generateRandomColor = () => {
	const letters = "0123456789ABCDEF";
	let color = "#";
	let shouldGenerateDarkColor = true;

	while (shouldGenerateDarkColor) {
		color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}

		const r = parseInt(color.slice(1, 3), 16);
		const g = parseInt(color.slice(3, 5), 16);
		const b = parseInt(color.slice(5, 7), 16);

		const yiq = (r * 299 + g * 587 + b * 114) / 1000;

		if (yiq < 128) {
			shouldGenerateDarkColor = false;
		}
	}

	return color;
};

export const createOption = (tag: any) => ({
	label: tag.name,
	value: tag._id,
	color: tag.color,
});
