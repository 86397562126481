import { useEffect } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useTags = () => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	const tags = commonObjIncStoreZustand((state) => state.tags);

	useEffect(() => {
		commonObjIncStoreZustand.getState().fetchTags({ wholesalerId });
	}, [wholesalerId]);

	const addTag = (name: string, color: string) => {
		const tag = commonObjIncStoreZustand
			.getState()
			.addTag({ name, color }, { wholesalerId });
		return tag;
	}

	return { tags, addTag }

}

export default useTags;