import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	success: {
		color: theme.palette.success.main,
	},
	error: {
		color: theme.palette.error.main,
	},
	hoveredRow: {
		backgroundColor: theme.palette.action.hover,
	},
	emptyBox: {
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(2),
		display: "flex",
		flex: 1,
	},
	headerCell: {
		minWidth: 155,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		marginRight: theme.spacing(1),
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	accountsResultsCell: {
		minWidth: 200,
	},
	firstHeaderCell: {
		minWidth: 155,
		zIndex: 3,
		backgroundColor: "#FAFAFA",
	},
	totalRow: {
		backgroundColor: "#E8E8E8",
		position: "sticky",
		bottom: 0,
		zIndex: 1,
		"& > *": {
			fontWeight: "bold",
			textTransform: "uppercase",
		},
	},
	buttonGroupContainer: {
		display: "flex",
		alignItems: "center",
		gap: theme.spacing(2),
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(1.2),
	},
	clickableRow: {
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	},
	statusRed: {
		color: "red",
	},
	statusYellow: {
		color: "#F4C01D",
	},
	statusGreen: {
		color: "#4E915E",
	},
	tableCell: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	overflowHidden: {
		overflow: "hidden",
	},
	tableContainerHeight: {
		maxHeight: "76.5vh",
	},
	loading: {
		alignItems: "center",
		width: "20px",
	},
	loadingFlex: {
		display: "flex",
	},
}));
