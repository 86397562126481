import { useEffect } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useTeamMembers = ({ userId }: { userId?: string }) => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	const teamMembers = commonObjIncStoreZustand((state) => state.teamMembers);

	useEffect(() => {
		commonObjIncStoreZustand
			.getState()
			.fetchTeamMembers({ wholesalerId }, userId);
	}, [wholesalerId, userId]);

	return {
		teamMembers,
	};
};

export default useTeamMembers;
