import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { delta, usd } from "../../utils/format";
import { Link } from "react-router-dom";
import useQuery, { ISalesRepsQuery } from "../../hooks/useQuery";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ordinal from "ordinal";
import { useLocation } from "react-router-dom";
import makeAvatarUrl from "../../utils/makeAvatarUrl";
import cloneDeep from "lodash/cloneDeep";
import qs from "qs";
import Tooltip from "@material-ui/core/Tooltip";

export type IOnItemClick = "navigate" | "compare";
interface IProps {
	userId: string;
	selected: boolean;
	onItemClick: IOnItemClick;
	backgroundColor?: string;
}

const useStyles = makeStyles((theme) => ({
	rankWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		width: 36,
		position: "relative",
		marginLeft: theme.spacing(),
	},
	rankChangeWrapper: {
		borderRadius: theme.shape.borderRadius,
		display: "flex",
		height: 20,
		width: "100%",
		backgroundColor: theme.palette.text.hint,
		alignItems: "center",
		justifyContent: "flex-end",
	},
	rankChangeUp: {
		backgroundColor: theme.palette.success.light,
	},
	rankChangeDown: {
		backgroundColor: theme.palette.error.light,
	},
	rankChangeText: {
		color: "white",
	},
	rankChangeIcon: {
		color: "white",
	},
}));

export default function UserListItem(props: IProps) {
	const user = useSelector(
		(state: RootState) => state.usersWarehouse.usersById[props.userId]
	);
	const currentUserRole = useSelector((state: RootState) => state.session.role);
	const isLeadership = useSelector(
		(state: RootState) => state.session.leadership
	);
	const location = useLocation();
	const { query } = useQuery<ISalesRepsQuery>();
	const classes = useStyles();
	if (!user) {
		return null;
	}
	const rankChangeWrapper = [classes.rankChangeWrapper];
	let Icon: typeof ArrowDropUpIcon | undefined;
	if (user.rankChange > 0) {
		rankChangeWrapper.push(classes.rankChangeUp);
		Icon = ArrowDropUpIcon;
	} else if (user.rankChange < 0) {
		rankChangeWrapper.push(classes.rankChangeDown);
		Icon = ArrowDropDownIcon;
	}
	let strippedQuery = cloneDeep(query);
	delete strippedQuery.compareWith;
	let to = {
		pathname: `/${user.role.toLowerCase()}s`,
		search: `?${qs.stringify({
			...strippedQuery,
			selectedUser: props.userId,
		})}`,
	};
	if (user.role === "teamLead" && user.leadership) {
		to.pathname = "/leadership";
	}
	if (props.onItemClick === "compare") {
		let compareWith = query.compareWith || [];
		if (compareWith.includes(props.userId)) {
			compareWith.splice(compareWith.indexOf(props.userId), 1);
		} else {
			compareWith.push(props.userId);
		}
		const q = qs.stringify({
			...query,
			compareWith: compareWith,
		});
		to = {
			pathname: location.pathname,
			search: `?${q}`,
		};
	}

	const style = props.backgroundColor
		? { style: { backgroundColor: props.backgroundColor } }
		: {};

	const canNavigate = () => {
		if (currentUserRole === "salesRep" && user.role === "teamLead") {
			return false;
		}
		if (currentUserRole === "teamLead" && user.leadership && !isLeadership) {
			return false;
		}
		return true;
	};

	return (
		<ListItem
			{...style}
			selected={props.selected}
			component={canNavigate() ? Link : "div"}
			to={to}
			onClick={
				canNavigate()
					? undefined
					: (e: React.MouseEvent<HTMLDivElement>) => e.preventDefault()
			}
		>
			<ListItemAvatar>
				<Avatar
					alt={user.name}
					src={makeAvatarUrl(user.wholesaler.customId, user.customId)}
				>
					{user.name
						.match(/\b(\w)/g)
						?.slice(0, 2)
						.join("")}
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={`${user.name} (${usd(user.totalPayout)})`}
				secondary={`GP: ${delta(user.gp.achieved)} / Vol: ${delta(
					user.volume.achieved
				)} / FPL: ${
					user.role === "teamLead" ? user.fpl.team : user.fpl.individual
				}`}
			/>
			{user.viewOnly || user.hidden ? (
				<>
					<Box className={classes.rankWrapper}>
						{user.hidden && <Typography variant="caption">Hidden</Typography>}
						{user.viewOnly && (
							<Tooltip title={"View-only user"}>
								<Typography variant="caption">VO</Typography>
							</Tooltip>
						)}
					</Box>
				</>
			) : (
				<Box className={classes.rankWrapper}>
					<Typography variant="caption">
						{typeof user?.rank === "number" ? ordinal(user.rank) : "-"}
					</Typography>
					{!!Icon && (
						<Box className={rankChangeWrapper.join(" ")}>
							<Typography variant="caption" className={classes.rankChangeText}>
								{user.rankChange}
							</Typography>
							<Icon fontSize="small" className={classes.rankChangeIcon} />
						</Box>
					)}
				</Box>
			)}
		</ListItem>
	);
}
