import { useEffect } from "react";
import useQuery, { IPaginatedQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";
import {
	CustomObjIncProductFilters,
	isIncentive,
} from "../types/commonObjIncTypes";
import { Incentive } from "../types/incentivesTypes";
import { Objective } from "../types/objectiveTypes";

const useSelectedCustomProducts = ({
	page,
	item,
	selectedProductsFilters,
	searchPhrase,
}: {
	page: number;
	item: Incentive | Objective;
	selectedProductsFilters: CustomObjIncProductFilters;
	searchPhrase?: string;
}) => {
	const { query } = useQuery<IPaginatedQuery>();
	const { wholesalerId } = query;

	let typeKey: string;

	if (isIncentive(item)) {
		typeKey = "incentiveId";
	} else {
		typeKey = "objectiveId";
	}

	const selectedCustomLoading = commonObjIncStoreZustand(
		(state) => state.selectedCustomProductsLoading
	);

	const selectedCustomProducts = commonObjIncStoreZustand(
		(state) => state.selectedCustomProducts
	);

	useEffect(() => {
		const extendedFilters = {
			...selectedProductsFilters,
			searchPhrase,
			wholesalerId,
		};
		commonObjIncStoreZustand.getState().fetchSelectedCustomProducts(
			{
				wholesalerId,
				page,
				[typeKey]: item?._id,
				isObjective: !isIncentive(item),
			},
			{ ...extendedFilters, searchPhrase }
		);
	}, [
		item,
		page,
		query,
		typeKey,
		wholesalerId,
		selectedProductsFilters,
		searchPhrase,
	]);

	return {
		selectedCustomLoading,
		selectedCustomProducts,
		selectedCustomProductsTotalPages:
			commonObjIncStoreZustand.getState().selectedCustomProductsTotalPages,
	};
};

export default useSelectedCustomProducts;
