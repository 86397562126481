import { useEffect, useState } from "react";
import {
	Button,
	ButtonGroup,
	CircularProgress,
	TextField,
	Typography,
} from "@material-ui/core";
import FilterButton from "../utils/TypeFilterButtons";
import Pagination from "@material-ui/lab/Pagination";
import { ItemCard } from "../utils/ItemCard";
import { useStyles } from "./ItemsList.styles";
import {
	Item,
	isIncentive,
} from "../../usersManagement/types/commonObjIncTypes";

export interface SelectAllState {
	[page: number]: boolean;
}

export interface ItemListProps<T extends Item> {
	itemType: string;
	loading: boolean;
	items: T[];
	currentPage: number;
	setCurrentPage: (page: number) => void;
	totalPages: number;
	onItemSelect: (selectedItems: T[]) => void;
	selectedItems: T[];
	onFilterChange: () => void;
	showArchived: boolean;
	setShowArchived: (value: boolean) => void;
	searchTerm?: string;
	onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	filterType: string;
	onFilterTypeChange: (type: string) => void;
}

export const ItemsList = <T extends Item>({
	itemType,
	items,
	currentPage,
	totalPages,
	loading,
	setCurrentPage,
	onItemSelect,
	selectedItems,
	showArchived,
	setShowArchived,
	searchTerm,
	onSearchChange,
	onFilterTypeChange,
}: ItemListProps<T>) => {
	const classes = useStyles();
	const [selectAllActivePages, setSelectAllActivePages] =
		useState<SelectAllState>({});
	const [selectAllArchived, setSelectAllArchived] = useState<SelectAllState>(
		{}
	);
	const [localSelectedItems, setLocalSelectedItems] = useState<T[]>(selectedItems);

	useEffect(() => {
		setLocalSelectedItems(selectedItems);
	}, [selectedItems]);


	const filterOptions = [
		{ label: "Binary", value: "Binary" },
		{ label: "Image", value: "Image" },
		{ label: "Quantity", value: "Quantity" },
		{ label: "Multi", value: "Multi" },
		{ label: "Validation", value: "Validation" },
		{ label: "Grouped", value: "Grouped" },
		{ label: "Image Validation", value: "ImageValidation" },
	];

	const handleItemSelect = (item: T) => {
		let updatedItems: T[] = [...localSelectedItems];

		const isSelected = localSelectedItems.some((inc) => inc._id === item._id);

		if (isSelected) {
			updatedItems = updatedItems.filter((inc) => inc._id !== item._id);

			if (item.type === 'grouped') {
				if (!isIncentive(item) && item.groupedObjectives) {
					updatedItems = updatedItems.filter(
						(inc) => !item.groupedObjectives?.some((groupedItem) => groupedItem._id === inc._id)
					);
				} else if (isIncentive(item) && item.groupedIncentives) {
					updatedItems = updatedItems.filter(
						(inc) => !item.groupedIncentives?.some((groupedItem) => groupedItem._id === inc._id)
					);
				}
			}
		} else {
			updatedItems.push(item);

			if (item.type === 'grouped') {
				if (!isIncentive(item) && item.groupedObjectives) {
					const newGroupedItems = item.groupedObjectives.filter(
						(groupedItem) =>
							!updatedItems.some(
								(selectedItem) => selectedItem._id === groupedItem._id
							)
					);
					updatedItems = [
						...updatedItems,
						...newGroupedItems,
					];
				} else if (isIncentive(item) && item.groupedIncentives) {
					const newGroupedItems = item.groupedIncentives.filter(
						(groupedItem) =>
							!updatedItems.some(
								(selectedItem) => selectedItem._id === groupedItem._id
							)
					);
					updatedItems = [
						...updatedItems,
						...newGroupedItems,
					];
				}
			}
		}

		setLocalSelectedItems(updatedItems);
		onItemSelect(updatedItems);

		const allItemsOnPage = getAllItemsOnCurrentPage();
		const allSelected = allItemsOnPage.every((inc) =>
			updatedItems.some((selectedInc) => selectedInc._id === inc._id)
		);

		if (showArchived) {
			setSelectAllArchived((prev) => ({
				...prev,
				[currentPage]: allSelected,
			}));
		} else {
			setSelectAllActivePages((prev) => ({
				...prev,
				[currentPage]: allSelected,
			}));
		}
	};

	const handleSelectAll = () => {
		const currentSelectAllState =
			(showArchived ? selectAllArchived : selectAllActivePages)[currentPage] || false;
		const newSelectAllState = !currentSelectAllState;

		const allItemsOnPage = getAllItemsOnCurrentPage();
		let updatedItems: T[];

		if (newSelectAllState) {
			updatedItems = [
				...localSelectedItems,
				...allItemsOnPage.filter(
					(item) => !localSelectedItems.some((selected) => selected._id === item._id)
				),
			];
		} else {
			updatedItems = localSelectedItems.filter(
				(selected) => !allItemsOnPage.some((item) => item._id === selected._id)
			);
		}

		setLocalSelectedItems(updatedItems);
		onItemSelect(updatedItems);

		if (showArchived) {
			setSelectAllArchived((prev) => ({
				...prev,
				[currentPage]: newSelectAllState,
			}));
		} else {
			setSelectAllActivePages((prev) => ({
				...prev,
				[currentPage]: newSelectAllState,
			}));
		}
	};

	const getAllItemsOnCurrentPage = () => {
		return items.reduce((acc: T[], item: T) => {
			if (item.type === 'multi') {
				if (isIncentive(item)) {
					if (item.subIncentives) {
						return [...acc, ...item.subIncentives as T[]];
					}
				} else {
					if (item.subObjectives) {
						return [...acc, ...item.subObjectives as T[]];
					}
				}
			}
			if (item.type === 'grouped') {
				if (isIncentive(item) && item.groupedIncentives) {
					return [...acc, item, ...item.groupedIncentives as T[]];
				} else if (!isIncentive(item) && item.groupedObjectives) {
					return [...acc, item, ...item.groupedObjectives as T[]];
				}
			}
			return [...acc, item];
		}, []);
	};

	const selectAll =
		(showArchived ? selectAllArchived : selectAllActivePages)[currentPage] ||
		false;

	return (
		<div className={classes.container}>
			<ButtonGroup className={classes.activeArchivedButtons}>
				<Button
					color="primary"
					onClick={() => setShowArchived(false)}
					variant={!showArchived ? "contained" : "outlined"}
					size="small"
					className={classes.widthButton}
				>
					{itemType}
				</Button>
				<Button
					color="primary"
					onClick={() => setShowArchived(true)}
					variant={showArchived ? "contained" : "outlined"}
					size="small"
					className={classes.widthButton}
				>
					Archive
				</Button>
			</ButtonGroup>
			<ButtonGroup
				size="small"
				aria-label="outlined primary button group"
				className={classes.typesButton}
			>
				<FilterButton types={filterOptions} onClick={onFilterTypeChange} />
				<Button
					onClick={handleSelectAll}
					variant={selectAll ? "contained" : "outlined"}
					color="primary"
					className={classes.widthButton}
				>
					{!selectAll ? "Select All" : "Deselect All"}
				</Button>
			</ButtonGroup>
			<TextField
				label="Search by title"
				size="small"
				variant="outlined"
				value={searchTerm}
				onChange={onSearchChange}
				className={classes.searchField}
			/>

			<div className={classes.itemsList}>
				{loading ? (
					<div className={classes.circularProgress}>
						<CircularProgress />
					</div>
				) : items.length === 0 ? (
					<Typography
						className={classes.notFound}
						variant="body1"
					>
						No {itemType} found
					</Typography>
				) : (
								items.map((item) => (
							<ItemCard
								key={item._id}
								item={item}
								handleItemSelect={handleItemSelect}
								selectedItems={localSelectedItems}
							/>
						))
				)}
			</div>
			{!loading && (
				<div className={classes.pagination}>
					<Pagination
						size="small"
						color="primary"
						count={totalPages}
						page={currentPage}
						onChange={(_, value) => setCurrentPage(value)}
					/>
				</div>
			)}

			{loading && (
				<div className={classes.circularProgress}>
					<CircularProgress />
				</div>
			)}
		</div>
	);
};