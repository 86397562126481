import React from 'react';
import {
	Box,
	Tab,
	Tabs,
} from '@mui/material';

const SelectionTabs = ({
	selectionType,
	setSelectionType,
}: {
		selectionType: boolean;
		setSelectionType: (selectionType: boolean) => void;
}) => {
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectionType(newValue === 0);
	};

	return (
		<Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '2rem' }}>
			<Tabs
				value={selectionType ? 0 : 1}
				onChange={handleChange}
				aria-label="selection type tabs"
			>
				<Tab value={0} label="Custom Selection" />
				<Tab value={1} label="Manual Selection" />
			</Tabs>
		</Box>
	);
};


export default SelectionTabs;
