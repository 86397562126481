import React, { useEffect, useState } from "react";
import {
	Checkbox,
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Button,
	Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { IBuyer } from "../../../../../../interfaces";
import useSelectedAccounts from "../../../../hooks/useSelectedAccounts";
import { Incentive } from "../../../../types/incentivesTypes";
import { Objective } from "../../../../types/objectiveTypes";
import snack from "../../../../../../utils/snack";
import { useStyles } from "./AccountsSelection.styles";

type SelectedAccountsComponentProps = {
	item: Incentive | Objective;
	searchTerm: string;
	accountType: string;
	premise: string;
	chains?: string[];
	locations?: string[];
	allSelected: boolean;
	clearSelection: boolean;
	setAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
	setClearSelection: React.Dispatch<React.SetStateAction<boolean>>;
};

const SelectedAccountsComponent: React.FC<SelectedAccountsComponentProps> = ({
	item,
	searchTerm,
	accountType,
	premise,
	chains,
	locations,
	allSelected,
	clearSelection,
	setAllSelected,
	setClearSelection,
}) => {
	const classes = useStyles();
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSelectedAccounts, setCurrentSelectedAccounts] = useState<
		{ buyerId: string; checked: boolean }[]
	>([]);

	const { selectedAccounts, totalPages, updateAccounts, selectedLoading } =
		useSelectedAccounts({
			page: currentPage,
			item: item,
			accountType:
				accountType !== "" && accountType !== "All" ? accountType : undefined,
			premise: premise !== "" ? premise : undefined,
			chains: chains ?? undefined,
			locations: locations ?? undefined,
			searchPhrase: searchTerm !== "" ? searchTerm : undefined,
		});

	useEffect(() => {
		if (selectedAccounts) {
			const initialSelections = selectedAccounts.map((acc: any) => ({
				buyerId: acc.customId,
				checked: acc.isSelected || false,
			}));
			setCurrentSelectedAccounts(initialSelections);
		}
	}, [selectedAccounts]);

	useEffect(() => {
		if (allSelected) {
			setCurrentSelectedAccounts((prev) =>
				prev.map((a) => ({ ...a, checked: true }))
			);
		}
	}, [allSelected]);

	useEffect(() => {
		if (clearSelection) {
			setCurrentSelectedAccounts((prev) =>
				prev.map((a) => ({ ...a, checked: false }))
			);
		}
	}, [clearSelection]);

	const handleCheck = (account: IBuyer) => {
		setCurrentSelectedAccounts((prev) => {
			const updatedAccounts = prev.map((a) => {
				if (a.buyerId === account.customId) {
					return { ...a, checked: !a.checked };
				}
				return a;
			});
			return updatedAccounts;
		});
	};

	const handleSave = (showSnack = false) => {
		const accountsToUpdate = currentSelectedAccounts.map((account) => ({
			buyerId: account.buyerId,
			checked: account.checked,
		}));
		updateAccounts(accountsToUpdate);
		if (showSnack) {
			snack.success("Accounts updated 👍");
		}
	};

	const handlePageChange = (_event: any, page: number) => {
		handleSave();
		setCurrentPage(page);
		setAllSelected(false);
		setClearSelection(false);
	};

	return (
		<div className={classes.listContainer}>
			<div className={classes.content}>
				{selectedLoading ? (
					<div className={classes.progress}>
						<CircularProgress size={"1rem"} />
					</div>
				) : !selectedAccounts || selectedAccounts.length === 0 ? (
					<Typography className={classes.notFound} variant="body1">
						No accounts found
					</Typography>
				) : (
					<Paper className={classes.table}>
						<TableContainer className={classes.tableContainer}>
							<Table aria-label="selected accounts table">
								<TableHead>
									<TableRow>
										<TableCell>Select</TableCell>
										<TableCell>Name</TableCell>
										<TableCell>Type</TableCell>
										{/* <TableCell>Premise</TableCell> */}
										<TableCell>Chain</TableCell>
										<TableCell>Location</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{selectedAccounts &&
										selectedAccounts.map((account: IBuyer) => (
											<TableRow key={account.customId}>
												<TableCell>
													<Checkbox
														edge="start"
														checked={
															currentSelectedAccounts.find(
																(a) => a.buyerId === account.customId
															)?.checked || false
														}
														onChange={() => handleCheck(account)}
														color="primary"
													/>
												</TableCell>
												<TableCell>{account.name}</TableCell>
												<TableCell>{account.accountType}</TableCell>
												{/* <TableCell>{account.premise}</TableCell> */}
												<TableCell>
													{account.chain ? account.chain : "-"}
												</TableCell>
												<TableCell>
													{account.location ? account.location : "-"}
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<div className={classes.saveButtonContainer}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => handleSave(true)}
								className={classes.button}
							>
								SAVE
							</Button>
						</div>
					</Paper>
				)}
				<div className={classes.pagination}>
					<Pagination
						count={totalPages}
						page={currentPage}
						onChange={handlePageChange}
						color="primary"
					/>
				</div>
			</div>
		</div>
	);
};

export default SelectedAccountsComponent;
