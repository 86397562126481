import {
	defaultAdvancedPayments,
	PayoutDetails,
} from "../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/utils";
import { ObjIncentiveImage } from "../../usersManagement/types/commonObjIncTypes";
import { capitalizeFirstLetter } from "../../usersManagement/utils";

export const columnKeyMap: Record<string, string> = {
	"User Name": "userName",
	Route: "route",
	"User Type": "userType",
	Objective: "objective",
	Incentive: "incentive",
	"Sub Objective": "subObjectives",
	"Sub Incentive": "subIncentives",
	"Grouped Objective": "groupedObjective",
	"Grouped Incentive": "groupedIncentive",
	Type: "type",
	"Start Date": "startDate",
	"End Date": "endDate",
	Status: "status",
	Quantity: "totalItems",
	Measure: "measure",
	"Grouped Quantity": "groupedQuantity",
	Target: "target",
	Potential: "payout",
	"Payout MTD": "payoutMTD",
	"Supplier Contribution": "supplierContribution",
	Image: "images",
	Accounts: "accounts",
	Products: "products",
	AccountsResults: "accountsResults",
	"Input Value": "valueInput",
	"Input Value Type": "valueInputType",
	"Minimum Quantity": "minQuantityValue",
	Weight: "weight",
	"Individual Status": "individualStatus",
	"Grouped Status": "groupedStatus",
	"Rejected Images": "rejectedImages",
	"Eligible Quantity": "eligibleQuantity",
	"Parent Name": "parentName",
};

export const transformColumnName = (colName: string): string => {
	return columnKeyMap[colName] || colName;
};

export const formatDate = (dateString: string): string => {
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
		timeZone: "UTC",
	};

	const date = new Date(dateString);
	return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatTime = (dateString: string) => {
	const options: Intl.DateTimeFormatOptions = {
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		timeZone: "UTC",
	};

	const date = new Date(dateString);
	return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatDateAndTime = (dateString: string) => {
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
		timeZone: "UTC",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
	};

	const date = new Date(dateString);

	return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const initialPayoutDetails: PayoutDetails = {
	target: 0,
	allOrNothingAmount: 0,
	perActionAmount: 0,
	atRiskAmount: 0,
	payoutType: "perAction",
	threshold: { units: "percentage", value: 0 },
	cap: { units: "percentage", value: 100 },
	isOpenEnded: false,
	advancedBudget: 0,
	advancedPayments: defaultAdvancedPayments,
	isAccountSpecific: false,
	isFullAmount: false,
};

export function formatTypeLabel(type: string): string {
	if (type)
		return type
			.replace(/([a-z])([A-Z])/g, "$1 $2")
			.replace(/^\w/, (c) => c.toUpperCase());
	return type;
}

export const mapImagesDataForExport = (
	rows: any[],
	includeQuantities: boolean
) => {
	return rows.flatMap((row) =>
		row.images.map((image: ObjIncentiveImage) => {
			const baseData = {
				[row.isObjective ? "Objective" : "Incentive"]: row.isObjective
					? row.objective
					: row.incentive || "",
				userName: capitalizeFirstLetter(row.userName) || "",
				userId: row.userId || "",
				route: row.route || "",
				Account: image.account || "",
				"Buyer Id": image.buyerId || "",
				Title: image.title || "",
				"Is Rejected": image.rejected ? "Yes" : "No",
				Date: formatDate(image.date) || "-",
				Time: formatTime(image.date) || "-",
				"Image Url": image.imageUrl || "",
				Notes: image.notes || "-",
				"Rejected Reason": image.rejectedReason || "-",
			};

			if (includeQuantities) {
				baseData["Minimum Quantity"] = row.minQuantityValue || "-";
				baseData["Quantity"] = row.totalItems || "-";
			}

			return baseData;
		})
	);
};


export const formatImagesDataToRows = (
	imagesData: any[],
	isObjective: boolean,
	includeQuantities: boolean
) => {
	const headers = [
		isObjective ? "Objective" : "Incentive",
		"User Name",
		"User Id",
		"Route",
		"Account",
		"Buyer Id",
		"Title",
		"Is Rejected",
		"Date",
		"Time",
		"Image Url",
		"Notes",
		"Rejected Reason",
	];

	if (includeQuantities) {
		headers.splice(6, 0, "Quantity", "Minimum Quantity");
	}

	const rows = imagesData.map((image) => {
		const rowData = [
			image["Objective"] || image["Incentive"],
			image.userName,
			image.userId,
			image.route,
			image.Account,
			image["Buyer Id"],
			image.Title,
			image["Is Rejected"],
			image.Date,
			image.Time,
			image["Image Url"],
			image.Notes,
			image["Rejected Reason"],
		];

		if (includeQuantities) {
			rowData.splice(6, 0, image.Quantity, image["Minimum Quantity"]);
		}

		return rowData;
	});

	let totalQuantity = 0;
	let totalMinimumQuantity = 0;

	if (includeQuantities) {
		imagesData.forEach((image) => {
			totalQuantity += parseFloat(image.Quantity) || 0;
			totalMinimumQuantity += parseFloat(image["Minimum Quantity"]) || 0;
		});

		const totalRow = new Array(headers.length).fill("");
		totalRow[0] = "Total";

		totalRow[6] = totalQuantity.toString();
		totalRow[7] = totalMinimumQuantity.toString();

		return [headers, ...rows, totalRow];
	}

	return [headers, ...rows];
};
