import React, { useState } from "react";
import useAccountTypes from "../../../../hooks/useAccountTypes";
import {
	FormControl,
	IconButton,
	Switch,
	FormControlLabel,
	TextField,
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	CircularProgress,
} from "@material-ui/core";
import Select from "react-select";
import { AddCircleOutline } from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { CustomObjIncAccountFilters } from "../../../../types/commonObjIncTypes";
import { useStyles } from "./AccountsSelection.styles";
import { Pagination } from "@material-ui/lab";
import useSelectedCustomAccounts from "../../../../hooks/useSelectedCustomAccounts"; // You'll need to create this hook

type CustomAccountsSelectionProps = {
	selectedAccountsFilters: CustomObjIncAccountFilters;
	setSelectedAccountsFilters: React.Dispatch<
		React.SetStateAction<CustomObjIncAccountFilters>
	>;
	item: any;
};

export const CustomAccountsSelection: React.FC<
	CustomAccountsSelectionProps
> = ({ selectedAccountsFilters, setSelectedAccountsFilters, item }) => {
	const classes = useStyles();
	const { accountTypes, chains, locations } = useAccountTypes();
	const [currentSelectedPage, setCurrentSelectedPage] = useState(1);
	const [showTable, setShowTable] = useState(false);
	const [searchPhrase, setSearchPhrase] = useState("");

	const premises = ["On Premise", "Off Premise"];

	const {
		selectedCustomAccounts,
		selectedCustomLoading,
		selectedCustomAccountsTotalPages,
	} = useSelectedCustomAccounts({
		page: currentSelectedPage,
		item,
		selectedAccountsFilters,
		searchPhrase,
	});

	const handleFilterChange = (filterType: string, values: string[]) => {
		setSelectedAccountsFilters((prevState: any) => ({
			...prevState,
			[filterType]: values,
		}));
	};

	const handleSelectAll = (filterType: string, options: string[]) => {
		setSelectedAccountsFilters((prevState: any) => ({
			...prevState,
			[filterType]: options,
		}));
	};

	const handleRemoveAll = (filterType: string) => {
		setSelectedAccountsFilters((prevState: any) => ({
			...prevState,
			[filterType]: [],
		}));
	};

	const handlePageChange = (_event: any, page: number) => {
		setCurrentSelectedPage(page);
	};

	const handleShowTableChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setShowTable(event.target.checked);
	};

	const handleSearchPhraseChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSearchPhrase(event.target.value);
		setSelectedAccountsFilters((prev) => ({
			...prev,
			searchPhrase: event.target.value,
		}));
	};

	return (
		<div className={classes.container}>
			{[
				{
					filterName: "accountType",
					label: "Account Types",
					options: accountTypes,
				},
				{ filterName: "premise", label: "Premise Types", options: premises },
				{ filterName: "chain", label: "Chains", options: chains },
				{ filterName: "location", label: "Locations", options: locations },
			].map(({ filterName, label, options }) => (
				<FormControl
					key={filterName}
					variant="outlined"
					className={classes.marginTop}
				>
					<div className={classes.rowContainer}>
						<Select
							className={classes.select}
							isMulti
							name={filterName}
							placeholder={`Select ${label}`}
							options={options.map((type) => ({
								value: type,
								label: type,
							}))}
							value={selectedAccountsFilters[
								filterName as keyof CustomObjIncAccountFilters
							]?.map((type) => ({
								value: type,
								label: type,
							}))}
							onChange={(multi) =>
								handleFilterChange(
									filterName,
									multi.map((type: any) => type.value)
								)
							}
							styles={{
								menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
							}}
							menuPortalTarget={document.body}
						/>
						<div className={classes.buttonContainer}>
							<IconButton
								color="primary"
								onClick={() => handleSelectAll(filterName, options)}
								aria-label="Select All"
								size="small"
							>
								<AddCircleOutline />
							</IconButton>
							<IconButton
								onClick={() => handleRemoveAll(filterName)}
								aria-label="Remove All"
								size="small"
							>
								<HighlightOffIcon />
							</IconButton>
						</div>
					</div>
				</FormControl>
			))}
			<FormControlLabel
				control={
					<Switch
						checked={showTable}
						onChange={handleShowTableChange}
						name="showTable"
						color="primary"
					/>
				}
				label="Show Selected Accounts"
				style={{ marginTop: "10px" }}
			/>
			{showTable && (
				<div>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						mb={2}
					>
						<TextField
							variant="outlined"
							size="small"
							label="Search Accounts"
							value={searchPhrase}
							onChange={handleSearchPhraseChange}
							style={{
								width: "200px",
								marginBottom: "10px",
								marginTop: "10px",
							}}
						/>
					</Box>
					<div
						className={classes.selectedTable}
						style={{
							height: selectedCustomAccounts.length === 0 ? "15vh" : "45vh",
						}}
					>
						{selectedCustomLoading ? (
							<div className={classes.progress}>
								<CircularProgress size={"1rem"} />
							</div>
						) : selectedCustomAccounts.length === 0 ? (
							<Typography className={classes.notFound} variant="body1">
								No accounts selected
							</Typography>
						) : (
							<Paper className={classes.table}>
								<TableContainer className={classes.tableContainer}>
									<Table stickyHeader aria-label="accounts table">
										<TableHead>
											<TableRow>
												<TableCell className={classes.headerCell}>
													Name
												</TableCell>
												<TableCell className={classes.headerCell}>
													Account Type
												</TableCell>
												{/* <TableCell className={classes.headerCell}>Premise</TableCell> */}
												<TableCell className={classes.headerCell}>
													Chain
												</TableCell>
												<TableCell className={classes.headerCell}>
													Location
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{selectedCustomAccounts.map((account: any) => (
												<TableRow key={account._id}>
													<TableCell>{account.name}</TableCell>
													<TableCell>{account.accountType}</TableCell>
													{/* <TableCell>{account.premise}</TableCell> */}
													<TableCell>{account.chain || "-"}</TableCell>
													<TableCell>{account.location || "-"}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						)}
					</div>
					<div className={classes.pagination}>
						<Pagination
							count={selectedCustomAccountsTotalPages}
							page={currentSelectedPage}
							onChange={handlePageChange}
							color="primary"
						/>
					</div>
				</div>
			)}
		</div>
	);
};
