import { useEffect } from "react";
import useQuery, { IPaginatedQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";
import {
	CustomObjIncAccountFilters,
	isIncentive,
} from "../types/commonObjIncTypes";
import { Incentive } from "../types/incentivesTypes";
import { Objective } from "../types/objectiveTypes";

const useSelectedCustomAccounts = ({
	page,
	item,
	selectedAccountsFilters,
	searchPhrase,
}: {
	page: number;
	item: Incentive | Objective;
	selectedAccountsFilters: CustomObjIncAccountFilters;
	searchPhrase?: string;
}) => {
	const { query } = useQuery<IPaginatedQuery>();
	const { wholesalerId } = query;

	let typeKey: string;

	if (isIncentive(item)) {
		typeKey = "incentiveId";
	} else {
		typeKey = "objectiveId";
	}

	const selectedCustomLoading = commonObjIncStoreZustand(
		(state) => state.selectedCustomAccountsLoading
	);

	const selectedCustomAccounts = commonObjIncStoreZustand(
		(state) => state.selectedCustomAccounts
	);

	useEffect(() => {
		const extendedFilters = {
			...selectedAccountsFilters,
			searchPhrase,
			wholesalerId,
		};
		commonObjIncStoreZustand.getState().fetchSelectedCustomAccounts(
			{
				wholesalerId,
				page,
				[typeKey]: item?._id,
				isObjective: !isIncentive(item),
			},
			extendedFilters
		);
	}, [
		item,
		page,
		query,
		typeKey,
		wholesalerId,
		selectedAccountsFilters,
		searchPhrase,
	]);

	return {
		selectedCustomLoading,
		selectedCustomAccounts,
		selectedCustomAccountsTotalPages:
			commonObjIncStoreZustand.getState().selectedCustomAccountsTotalPages,
	};
};

export default useSelectedCustomAccounts;
