import { useState } from "react";
import { formatDate, formatTypeLabel } from "./utils";
import {
	Box,
	Collapse,
	Grid,
	IconButton,
	Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
	Item,
	isIncentive,
} from "../../usersManagement/types/commonObjIncTypes";
import SubItemCard from "./SubItemCard";
import { useStyles } from "./ItemCard.styles";
import { capitalizeFirstLetter } from "../../usersManagement/utils";


interface ItemCardProps<T extends Item> {
	item: T;
	handleItemSelect: (item: T) => void;
	selectedItems: any;
}

export const ItemCard = <T extends Item>({
	item,
	handleItemSelect,
	selectedItems,
}: ItemCardProps<T>) => {
	const classes = useStyles();
	const [isExpanded, setIsExpanded] = useState(false);
	const toggleExpand = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setIsExpanded(!isExpanded);
	};

	const handleSelect = () => {
		if (item.type === "multi") {
			setIsExpanded(!isExpanded);
		} else {
			handleItemSelect(item);
		}
	};

	const handleSubSelect = (
		subObjective: any,
		event?: React.MouseEvent<Element>
	) => {
		if (event) {
			event.stopPropagation();
		}
		handleItemSelect(subObjective);
	};

	const checkAllSubItemsSelected = () => {
		if (item.type === "multi") {
			if (isIncentive(item)) {
				if (item.subIncentives) {
					return item.subIncentives.every((subIncentive) =>
						selectedItems.some((selectedItem: any) => selectedItem._id === subIncentive._id)
					);
				}
			} else {
				if (item.subObjectives) {
					return item.subObjectives.every((subObjective) =>
						selectedItems.some((selectedItem: any) => selectedItem._id === subObjective._id)
					);
				}
			}
		}
		return false;
	};

	const getBackgroundColor = (
		item: any,
		selectedItems: any[],
		checkAllSubItemsSelected: () => boolean,
	) => {
		if (item.type === 'multi') {
			return checkAllSubItemsSelected() ? "#e8eaf6" : "";
		} else {
			return selectedItems.some(
				(selectedItem: any) => selectedItem._id === item._id,
			)
				? "#e8eaf6"
				: "";
		}
	};


	return (
		<Box
			className={classes.card}
			onClick={handleSelect}
			style={{
				backgroundColor: getBackgroundColor(item, selectedItems, checkAllSubItemsSelected),
			}}
		>
			<div className={classes.mainBox}>
				<div className={classes.title}>{capitalizeFirstLetter(item.name)}</div>
				<IconButton onClick={toggleExpand}>
					{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
				</IconButton>
			</div>
			<Collapse in={isExpanded} timeout="auto" unmountOnExit>
				<Box className={classes.expandedContent}>
					<Grid container spacing={2}>
						<Grid
							container
							spacing={2}
							className={classes.expandedText}
						>
							<Grid item xs={12}>
								<Typography variant="subtitle2">
									Type:
								</Typography>
								<Typography variant="body2">
									{formatTypeLabel(item.type)}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="subtitle2">
									Period:
								</Typography>
								<Typography variant="body2">
									{item.customPeriodStart &&
										formatDate(item.customPeriodStart)}{" "}
									-{" "}
									{item.customPeriodEnd &&
										formatDate(item.customPeriodEnd)}
								</Typography>
							</Grid>
						</Grid>
						{item.type === "multi" &&
							!isIncentive(item) &&
							item.subObjectives && (
								<Grid item xs={12}>
									<Typography variant="subtitle2">
										Sub Objectives:
									</Typography>
									<Box className={classes.subItemContainer}>
										{item.subObjectives.map(
											(subObjective) => (
												<SubItemCard
													key={subObjective._id}
													subItem={subObjective}
													handleSubSelect={
														handleSubSelect
													}
													isSelected={selectedItems.some((selectedItem: any) => selectedItem._id === subObjective._id)}
												/>
											)
										)}
									</Box>
								</Grid>
							)}
						{item.type === "multi" &&
							isIncentive(item) &&
							item.subIncentives && (
								<Grid item xs={12}>
									<Typography variant="subtitle2">
										Sub Incentives:
									</Typography>
									<Box className={classes.subItemContainer}>
										{item.subIncentives.map(
											(subIncentive) => (
												<SubItemCard
													key={subIncentive._id}
													subItem={subIncentive}
													handleSubSelect={
														handleSubSelect
													}
													isSelected={selectedItems.some((selectedItem: any) => selectedItem._id === subIncentive._id)}
												/>
											)
										)}
									</Box>
								</Grid>
							)}
						{item.type === "grouped" &&
							!isIncentive(item) &&
							item.groupedObjectives && (
								<Grid item xs={12}>
									<Typography variant="subtitle2">
										Grouped Objectives:
									</Typography>
									<Box className={classes.subItemContainer}>
										{item.groupedObjectives.map(
											(child) => (
												<SubItemCard
													key={child._id}
													subItem={child}
													handleSubSelect={
														handleSubSelect
													}
													isSelected={selectedItems.some((selectedItem: any) => selectedItem._id === child._id)}
												/>
											)
										)}
									</Box>
								</Grid>
							)}
						{item.type === "grouped" &&
							isIncentive(item) &&
							item.groupedIncentives && (
								<Grid item xs={12}>
									<Typography variant="subtitle2">
										Grouped Incentives:
									</Typography>
									<Box className={classes.subItemContainer}>
										{item.groupedIncentives.map(
											(child) => (
												<SubItemCard
													key={child._id}
													subItem={child}
													handleSubSelect={
														handleSubSelect
													}
													isSelected={selectedItems.some((selectedItem: any) => selectedItem._id === child._id)}
												/>
											)
										)}
									</Box>
								</Grid>
							)}
					</Grid>
				</Box>
			</Collapse>
		</Box>
	);
};
