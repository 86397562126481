import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import NotFoundView from '../notFound/NotFoundView';

interface PrivateRouteProps {
    component: React.ComponentType<any>;
    roles?: string[];
    exact?: boolean;
    path: string;
    requiresLeadership?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    component: Component,
    roles = [],
    requiresLeadership = false,
    ...rest
}) => {
    const role = useSelector((state: RootState) => state.session.role);
    const isLeadership = useSelector((state: RootState) => state.session.leadership);

    const hasAccess = () => {
        if (roles.includes(role)) {
            if (role === 'teamLead') {
                return !requiresLeadership || isLeadership;
            }
            return true;
        }

        return false; 
    };


    return (
        <Route
            {...rest}
            render={(props) =>
                hasAccess() ? (
                    <Component {...props} />
                ) : (
                    <NotFoundView />
                )
            }
        />
    );
};

export default PrivateRoute;
