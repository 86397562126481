import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles({
	container: {
		maxHeight: "100px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	list: {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		maxHeight: "300px",
		overflowY: "auto",
	},
	title: {
		textAlign: "center",
		marginBottom: "1rem"
	},
	info: {
		padding: "1rem 5rem 1rem 5rem"
	},
	dialogImage: {
		maxWidth: "80%",
		height: "auto",
		marginBottom: "1rem",
	},
	notes: {
		margin: "0 3.5rem",
		maxHeight: "9rem",
		overflowY: "auto",
		whiteSpace: "pre-wrap",
		wordBreak: "break-word",
	},
	notesInfo: {
		margin: "1rem 3.5rem 0.5rem 3.5rem"
	},
	dialogContent: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
	},
	content: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: "1rem",
	},
	navigationButton: {
		fontSize: "2rem",
	},
	buttons: {
		display: "flex",
		justifyContent: "space-between",
		margin: "1rem 4.3rem",
	},
	rejectContainer: {
		margin: "0 3.5rem",
	},
	image: {
		width: "80px",
    	height: "auto",
    	objectFit: "cover",
	},
	leftButtons: {
		display: "flex",
		gap: "8px",
		flexGrow: 1,
	},
	textArea: {
		width: '100%', 
		padding: '8px', 
		marginTop: '8px'
	}
});