import { Button } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import { mapForExport } from "./mapForExport";
import exportCsv from "../../../utils/exportCsv";

interface ExportCSVButtonProps {
	columns: string[];
	rows: any[];
	totals: {
		payout: number;
		payoutMTD: number;
		target: number;
		quantity: number;
		eligibleQuantity: number;
		supplierContribution?: number;
	};
}

export const ExportCSVButton = ({
	columns,
	rows,
	totals,
}: ExportCSVButtonProps) => {
	const typeIndex = columns.indexOf("Type");
	const filteredColumns = [
		...columns.slice(0, typeIndex),  
		"Parent Name",                 
		...columns.slice(typeIndex).filter(
			(column) => column !== "Accounts" && column !== "Products" && column !== "Accounts Results"
		),
	];

	const totalRow = [
		"Total",
		...filteredColumns.slice(1).map((column) => {
			if (column === "Potential") return totals?.payout || 0;
			if (column === "Payout MTD") return totals?.payoutMTD || 0;
			if (column === "Target") return totals?.target || 0;
			if (column === "Quantity") return totals?.quantity || 0;
			if (column === "Eligible Quantity") return totals?.eligibleQuantity || 0;
			if (column === "Supplier Contribution") return totals?.supplierContribution || 0;
			return "";
		}),
	];
	const transformedRows = rows.map((row) => mapForExport(row, filteredColumns));

	const handleExport = () => {
		exportCsv("Reporting Table", [
			filteredColumns,
			...transformedRows,
			totalRow,
		]);
	};

	return (
		<Button
			size="small"
			variant="outlined"
			color="primary"
			startIcon={<DownloadIcon />}
			onClick={handleExport}
		>
			Export CSV
		</Button>
	);
};
