import React, { useState } from "react";
import { Incentive } from "../../../../types/incentivesTypes";
import { Objective } from "../../../../types/objectiveTypes";
import useAccountTypes from "../../../../hooks/useAccountTypes";
import {
	Box,
	FormControlLabel,
	Switch,
	TextField,
	Typography,
} from "@material-ui/core";
import { SelectionControls } from "../../selectComponent/SelectionControls";
import { useStyles } from "./AccountsSelection.styles";
import { MultiSelect, SingleSelect } from "../../../../../select/Select";
import AllAccountsComponent from "./AllAccountsComponent";
import SelectedAccountsComponent from "./SelectedAccountsComponent";

type ManualAccountsSelectionProps = {
	item: Incentive | Objective;
};

export const ManualAccountsSelection: React.FC<
	ManualAccountsSelectionProps
> = ({ item }) => {
	const classes = useStyles();
	const { accountTypes, chains, locations } = useAccountTypes();

	const [searchTerm, setSearchTerm] = useState("");
	const [selectedAccountType, setSelectedAccountType] = useState("");
	const [premise, setPremise] = useState("");
	const [selectedChains, setSelectedChains] = useState();
	const [selectedLocations, setSelectedLocations] = useState();
	const [showSelected, setShowSelected] = useState<boolean>(false);
	const [allSelected, setAllSelected] = useState<boolean>(false);
	const [clearSelection, setClearSelection] = useState<boolean>(false);

	const handleShowSelectedChange = () => {
		setShowSelected((prev) => !prev);
		setAllSelected(false);
	};

	const handleSearchChange = (event: any) => {
		setSearchTerm(event.target.value);
	};

	const handleSelectAll = () => {
		setAllSelected(true);
		setClearSelection(false);
	};

	const handleClearSelection = () => {
		setClearSelection(true);
		setAllSelected(false);
	};

	const handleSelect =
		(filterName: "chains" | "locations") => (selected: any) => {
			if (filterName === "chains") {
				setSelectedChains(selected);
			} else {
				setSelectedLocations(selected);
			}
		};

	return (
		<div className={classes.manualContainer}>
			<Box className={classes.flexColumn}>
				<div className={classes.flexColumn}>
					<SelectionControls
						onSelectAll={handleSelectAll}
						onClear={handleClearSelection}
					/>
					<TextField
						label="Search by title"
						size="small"
						variant="outlined"
						defaultValue={searchTerm}
						onChange={handleSearchChange}
					/>
					<FormControlLabel
						control={
							<Switch
								checked={showSelected}
								onChange={handleShowSelectedChange}
								color="primary"
							/>
						}
						label="Show Selected"
					/>
				</div>
				<div>
					<Typography variant="body1" className={classes.text}>
						Filters:
					</Typography>
					<div className={classes.filters}>
						<SingleSelect
							label="Account Type"
							options={accountTypes}
							defaultValue={selectedAccountType}
							onSelect={(value) => setSelectedAccountType(value as string)}
						/>
						<SingleSelect
							label="Premise"
							options={["On Premise", "Off Premise"]}
							defaultValue={premise}
							onSelect={(value) => {
								if (value === "On Premise") {
									setPremise("on");
								} else {
									setPremise("off");
								}
							}}
						/>
						<MultiSelect
							label="Chain"
							options={chains}
							selected={selectedChains}
							onSelect={handleSelect("chains")}
						/>
						<MultiSelect
							label="Location"
							options={locations}
							selected={selectedLocations}
							onSelect={handleSelect("locations")}
						/>
					</div>
				</div>
			</Box>
			{showSelected ? (
				<SelectedAccountsComponent
					item={item}
					searchTerm={searchTerm}
					accountType={selectedAccountType}
					premise={premise}
					chains={selectedChains}
					locations={selectedLocations}
					allSelected={allSelected}
					clearSelection={clearSelection}
					setAllSelected={setAllSelected}
					setClearSelection={setClearSelection}
				/>
			) : (
				<AllAccountsComponent
					item={item}
					searchTerm={searchTerm}
					accountType={selectedAccountType}
					premise={premise}
					chains={selectedChains}
					locations={selectedLocations}
					allSelected={allSelected}
					clearSelection={clearSelection}
					setAllSelected={setAllSelected}
					setClearSelection={setClearSelection}
				/>
			)}
		</div>
	);
};
