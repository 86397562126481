import { useEffect, useState } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../../hooks/useQuery";
import { incentivesStoreZustand } from "../../store/incentivesStore";

const useReportGroupedIncentives = ({
	searchPhrase,
}: {
	searchPhrase?: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [secondaryPageSize, setSecondaryPageSize] = useState(10);

	const { incentives, reportingGroupedPage, setReportingGroupedPage } =
		incentivesStoreZustand();

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		const fetchAllChildren = async () => {
			const { incentives } = incentivesStoreZustand.getState();
			incentives.forEach((incentive) => {
				if (incentive.type === "grouped") {
					incentivesStoreZustand
						.getState()
						.fetchChildren({ wholesalerId }, incentive._id);
				}
			});
			
		};
		incentivesStoreZustand.getState().fetchIncentivesReportTable(
			{
				wholesalerId,
				page: reportingGroupedPage,
				limit: secondaryPageSize,
				searchPhrase,
				type: "grouped",
			},
			fetchAllChildren
		);
	}, [wholesalerId, reportingGroupedPage, secondaryPageSize, searchPhrase]);

	return {
		incentives,
		reportingGroupedPage,
		setReportingGroupedPage,
		secondaryPageSize,
		setSecondaryPageSize,
		totalPages: incentivesStoreZustand((state) => state.totalPages),
		loading: incentivesStoreZustand((state) => state.loading),
		setLoading: incentivesStoreZustand((state) => state.setLoading),
	};
};

export default useReportGroupedIncentives;
