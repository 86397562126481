import { Button } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import exportCsv from "../../../utils/exportCsv";
import { formatImagesDataToRows, mapImagesDataForExport } from "./utils";


interface ExportImagesCSVProps {
    rows: any[];
    rowTypes: any[];
}

export const ExportImagesCSV = ({ rows, rowTypes }: ExportImagesCSVProps) => {
    const includeQuantities = rowTypes.includes("imageValidation");
    
    const imagesData = mapImagesDataForExport(rows, includeQuantities);
    const isObjective = rows.length > 0 && rows[0].isObjective;

    const csvRows = formatImagesDataToRows(imagesData, isObjective, includeQuantities);


    const handleExport = () => {
        exportCsv("Images", csvRows);
    };

    return (
        <Button
            size="small"
            variant="outlined"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={handleExport}
        >
            Export Images CSV
        </Button>
    )
}
