import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		marginLeft: "0",
	},
	button: {
		flex: 1,
		maxWidth: "17%",
	},
	marginTop: {
		marginTop: 10,
	},
	buttons: {
		display: "flex",
		flexDirection: "row",
		gap: "2rem",
		alignItems: "center",
		marginBottom: "1rem",
	},
}));
