import { useEffect, useState } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { incentivesStoreZustand } from "../../objectivesIncentives/store/incentivesStore";

const useArchivedIncentives = ({ searchPhrase }: { searchPhrase?: string }) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();

	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [pageSize, setPageSize] = useState(15);

	const loadingArchived = incentivesStoreZustand(
		(state) => state.loadingArchived
	);
	const archivedCurrentPage = incentivesStoreZustand(
		(state) => state.archivedCurrentPage
	);
	const setArchivedCurrentPage = incentivesStoreZustand(
		(state) => state.setArchivedCurrentPage
	);
	const archivedTotalPages = incentivesStoreZustand(
		(state) => state.archivedTotalPages
	);

	const archivedIncentives = incentivesStoreZustand(
		(state) => state.archivedIncentives
	);

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		const fetchAllSubIncentives = () => {
			const { archivedIncentives } = incentivesStoreZustand.getState();
			archivedIncentives.forEach((incentive) => {
				if (incentive.type === "multi") {
					incentivesStoreZustand
						.getState()
						.fetchSubIncentives(
							{ wholesalerId },
							incentive._id,
							true
						);
				}
			});
		};
		incentivesStoreZustand.getState().fetchArchivedIncentives(
			{
				wholesalerId,
				page: archivedCurrentPage,
				limit: pageSize,
				searchPhrase,
			},
			fetchAllSubIncentives
		);
	}, [wholesalerId, archivedCurrentPage, pageSize, searchPhrase]);

	return {
		archivedIncentives,
		archivedCurrentPage,
		loadingArchived,
		archivedTotalPages,
		setArchivedCurrentPage,
		setPageSize,
	};
};

export default useArchivedIncentives;